/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import cx from 'classnames';
import axios from 'axios';
import classes from './FormSelectYourSeat.module.scss';
import colours from './SeatingMap.module.scss';
import FormTitle from '../FormTitle/FormTitle';
import * as ROUTES from '../../constants/routes';
import FormController from '../../components/FormController/FormController';
//import seatingMap from '../../assets/images/nationals-seating-map.svg';
import SelectNumOfSeatsModal from './SelectNumOfSeatsModal/SelectNumOfSeatsModal';
import SeatingMap from './SeatingMap';
import SeatMapModal from './SeatMapModal';
import Spinner from '../Loader/Spinner';
class FormSelectYourSeat extends Component {
  componentDidMount() {
    this.setState({
      seatInfoSelectedKey: '',
      seatDropdown: false,
      showMapModal: false
    });
    if (this.props.formStart === '') {
      this.props.history.push('/');
    }
    if (this.props.numOfSeat !== '') {
      if (this.props.formSize !== 'ballPark') {
        this.setState({
          numOfSeatsModalConfirm: true
        });
      }
    }
    if (this.props['selectYourSeatsUuid'] !== '') {
      axios
        .post(
          'https://nationals.vipfanportal.com/ticketfinder/getAvailability',
          {
            uuid: this.props['selectYourSeatsUuid'],
            q: this.props.numOfSeat !== '' ? this.props.numOfSeat : 1
          }
        )
        .then(response => {
          this.setState({ seatsInfo: response.data.info });
        })
        .catch(error => {
          console.log('error', error);
          this.setState({ error: true });
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.numOfSeats !== this.state.numOfSeats ||
      prevProps.selectYourSeatsUuid !== this.props.selectYourSeatsUuid
    ) {
      axios
        .post(
          'https://nationals.vipfanportal.com/ticketfinder/getAvailability',
          {
            uuid: this.props['selectYourSeatsUuid'],
            q: this.props.numOfSeat !== '' ? this.props.numOfSeat : 1
          }
        )
        .then(response => {
          this.setState({ seatsInfo: response.data.info });
        })
        .catch(error => {
          console.log('error', error);
          this.setState({ error: true });
        });
    }
  }
  onClickDifferentPlan = () => {
    if (this.props.formSize === 'fortyClassic') {
      if (this.props.selectYourSeatsUuid === 'zN6VE3n0B5') {
        this.props.updateSelectedChoices('selectYourSeatsUuid', 'CAFbHgRVNg');
      } else {
        this.props.updateSelectedChoices('selectYourSeatsUuid', 'zN6VE3n0B5');
      }
    } else {
      this.props.history.push({
        pathname: 'recommended-plan',
        state: { showModal: true }
      });
    }
  };
  onClickSelectSeat = index => {
    this.setState({
      seatInfoSelectedKey: index
    });
    this.props.updateSelectedChoices(
      'seatInfoSelected',
      this.state.seatsInfo[index]
    );
  };
  onMouseEnterSeat = index => {
    this.setState({
      seatInfoHoveredKey: this.state.seatsInfo[index]
    });
  };
  onMouseOutSeat = index => {
    this.setState({
      seatInfoHoveredKey: null
    });
  };
  onClickNumOfSeatItem = numOfSeats => {
    this.setState({
      numOfSeats: numOfSeats
    });
    this.props.updateSelectedChoices('numOfSeat', numOfSeats);
  };
  onClickNumOfSeatModalConfirm = () => {
    if (this.props.formSize === 'ballPark') {
      this.setState(prevState => ({
        numOfSeatsModalConfirm: !prevState.numOfSeatsModalConfirm
      }));
      this.props.updateSelectedChoices(
        'seatInfoSelected',
        this.state.seatsInfo[0]
      );
      this.props.history.push('checkout');
    } else {
      this.setState(prevState => ({
        numOfSeatsModalConfirm: !prevState.numOfSeatsModalConfirm
      }));
    }
  };
  onClickToggleSeatDropdown = () => {
    this.setState(prevState => ({
      seatDropdown: !prevState.seatDropdown
    }));
  };
  onClickCloseMapModal = () => {
    this.setState({ showMapModal: false });
  };
  onClickOpenMapModal = () => {
    this.setState({ showMapModal: true });
  };
  state = {
    seatsInfo: '',
    error: false,
    seatInfoSelectedKey: '',
    numOfSeats: '',
    numOfSeatsModalConfirm: false
  };
  render() {
    let seatNumberArr = [1, 2, 3, 4, 5, 6];

    // let seatColor = {
    //   'Infield Box': '#65ADA3',
    //   'Baseline Box': '#395FA2',
    //   'Baseline Reserved"': '#93D8F5',
    //   'LF/RF Corner': '#1C4078',
    //   'Outfield Reserved Front Row': '#C72034',
    //   'Outfield Reserved': '#C72034',
    //   'Club Level Front Row': 'red',
    //   'Club Level': 'red',
    //   'LF/RF Mezzanine Front Row': 'red',
    //   'LF/RF Mezzanine': 'red',
    //   'Gallery Front Row"': 'red',
    //   Gallery: 'red',
    //   'Upper Gallery': 'red'
    // };
    let planTitle = '';
    let planName = '';
    let adaLink = '';
    let switchPlanMsg = '';
    let flexMultiply = 1;
    switch (this.props['selectYourSeatsUuid']) {
      // classic 80
      case 'xa79PAlovb':
        planTitle = '82-Game Classic Plan';
        switchPlanMsg = '';
        adaLink =
          'https://purchase.tickets.com/buy/MLBEventInfo?agency=MLBSS_ADA&pid=8745102';
        break;
      // Classic 40
      case 'zN6VE3n0B5':
        planTitle = '42-Game Classic Plan';
        planName = '(Friday/Sunday)';
        adaLink =
          'https://purchase.tickets.com/buy/MLBEventInfo?agency=MLBSS_ADA&pid=8745105';
        switchPlanMsg =
          'Check out seating locations for the Saturday/Midweek 42-Game Classic Plan';
        break;
      case 'CAFbHgRVNg':
        planTitle = '42-Game Classic Plan';
        planName = '(Saturday/Midweek)';
        adaLink =
          'https://purchase.tickets.com/buy/MLBEventInfo?agency=MLBSS_ADA&pid=8745108';
        switchPlanMsg =
          'Check out seating locations for the Friday/Sunday 42-Game Classic Plan';
        break;
      // Classic 22
      case 'buTMiiX4Er':
        planTitle = '22-Game Classic Plan';
        planName = "(Abe's Plan)";
        adaLink =
          'https://purchase.tickets.com/buy/MLBEventInfo?agency=MLBSS_ADA&pid=8745111';
        switchPlanMsg =
          'Check out seating locations for one of the other 22-Game Classic Plans';
        break;
      case 'lRcyJMLnKk':
        planTitle = '22-Game Classic Plan';
        planName = "(George's Plan)";
        adaLink =
          'https://purchase.tickets.com/buy/MLBEventInfo?agency=MLBSS_ADA&pid=8745114';
        switchPlanMsg =
          'Check out seating locations for one of the other 22-Game Classic Plans';
        break;
      case 'TLpAWWaMkk':
        planTitle = '22-Game Classic Plan';
        planName = "(Tom's Plan)";
        adaLink =
          'https://purchase.tickets.com/buy/MLBEventInfo?agency=MLBSS_ADA&pid=8745117';
        switchPlanMsg =
          'Check out seating locations for one of the other 22-Game Classic Plans';
        break;
      case '9XZEeo7qMz':
        planTitle = '22-Game Classic Plan';
        planName = "(Teddy's Plan)";
        adaLink =
          'https://purchase.tickets.com/buy/MLBEventInfo?agency=MLBSS_ADA&pid=8745120';
        switchPlanMsg =
          'Check out seating locations for one of the other 22-Game Classic Plans';
        break;
      case 'beInIw45nx':
        planTitle = '10-Game Classic Plan';
        planName = '(Big Game Plan)';
        switchPlanMsg =
          'Check out seating locations for one of the other 10-Game Classic Plans';
        break;
      case 'EJ41wkGOLk':
        planTitle = '10-Game Classic Plan';
        planName = '(Sunday Plan)';
        switchPlanMsg =
          'Check out seating locations for one of the other 10-Game Classic Plans';
        break;
      case 'uy55P4lmFk':
        planTitle = '10-Game Classic Plan';
        planName = '(Opening Day Plan)';
        switchPlanMsg =
          'Check out seating locations for one of the other 10-Game Classic Plans';
        break;
      case '54C8ZCSb4s':
        planTitle = '5-Game Classic Plan';
        planName = '(Bobblehead Plan)';
        switchPlanMsg =
          'Check out seating locations for one of the other 5-Game Classic Plans';
        break;
      case 'v0C11qgztN':
        planTitle = '5-Game Classic Plan';
        planName = '(Opening Day Plan)';
        switchPlanMsg =
          'Check out seating locations for one of the other 5-Game Classic Plans';
        break;
      case 'baxawZnlK1':
        planTitle = '5-Game Classic Plan';
        planName = '(Saturday Plan)';
        switchPlanMsg =
          'Check out seating locations for one of the other 5-Game Classic Plans';
        break;
      case '6WPuBrtT8C':
        planTitle = '80-Ticket Flexible Plan';
        planName = '';
        flexMultiply = 80;
        switchPlanMsg =
          'Check out seating locations for one of the other 80-Ticket Flexible Plan';
        break;
      case 'yHsZtmvU3N':
        planTitle = '40-Ticket Flexible Plan';
        planName = '';
        flexMultiply = 40;
        switchPlanMsg =
          'Check out seating locations for one of the other 40-Ticket Flexible Plan';
        break;
      case 'LHsIqghb0k':
        planTitle = '20-Ticket Flexible Plan';
        planName = '';
        flexMultiply = 20;
        switchPlanMsg =
          'Check out seating locations for one of the other 20-Ticket Flexible Plan';
        break;

      default:
        break;
    }

    let showSeatInfo =
      this.state.seatsInfo !== '' &&
      typeof this.state.seatsInfo !== 'undefined' ? (
        this.state.seatsInfo.map((res, index) => (
          <div
            key={index}
            className={cx(
              classes.listOfSeatRow,
              this.state.seatInfoSelectedKey === index
                ? classes.seatInfoSelected
                : ''
            )}
            onClick={() => this.onClickSelectSeat(index)}
            onMouseEnter={() => this.onMouseEnterSeat(index)}
            onMouseLeave={() => this.onMouseOutSeat()}
          >
            <div className={classes.seatTitle}>
              <span
                className={
                  colours[res.name.toLowerCase().replace(/[/ ]/g, '_')]
                }
              ></span>
              <div className={classes.seatName}>{res.name}</div>
            </div>
            <div className={classes.price}>
              $
              {this.props.formStyle === 'classic'
                ? res.price.toLocaleString('en-US')
                : (res.price * flexMultiply).toLocaleString('en-US')}
              {/* ${res.price.toLocaleString('en-US')} */}
            </div>
            <div className={classes.selectSeatButton}>Select</div>
          </div>
        ))
      ) : (
        <div
          className={cx(
            classes.loader__wrapper,
            this.state.loaderShow ? classes.loader__wrapper__active : ''
          )}
        >
          <div className={classes.loader}>
            <Spinner />
          </div>
        </div>
      );
    return this.props['selectYourSeatsUuid'] !== '' ? (
      <div>
        <FormTitle title="Select Your Seats" />
        <div className={classes.FormSelectYourSeat}>
          <div className={classes.listOfSeats}>
            <div className={classes.toggleNumOfSeatModalHeader}>
              <div
                className={classes.toggleNumOfSeatButton}
                onClick={() => this.onClickToggleSeatDropdown()}
              >
                <span>
                  {this.props.numOfSeat !== '' ? this.props.numOfSeat : '1'}{' '}
                  {this.props.formStyle === 'classic' ? 'Seat' : 'Plan'}
                  {this.props.numOfSeat !== 1 ? 's' : ''}
                </span>
                <i className={'fa fa-chevron-down'} aria-hidden="true"></i>
                <div
                  className={cx(
                    classes.toggleNumSeatDropdown,
                    this.state.seatDropdown === true ? classes.active : ''
                  )}
                >
                  <ul>
                    {seatNumberArr.map((seatNum, i) => (
                      <li
                        key={i}
                        className={
                          this.state.numOfSeats === seatNum
                            ? classes.seatActive
                            : ''
                        }
                        onClick={() => this.onClickNumOfSeatItem(seatNum)}
                      >
                        {seatNum} {seatNum === 1 ? 'Seat' : 'Seats'}
                        <i className={'fa fa-check'} aria-hidden="true"></i>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className={classes.showSeatInfoTitle}>
                <span>Section Name</span>
                <span>
                  Price per{' '}
                  {this.props.formStyle === 'classic' ? 'Seat' : 'Plan'}
                </span>
              </div>
            </div>
            <div className={classes.showSeatInfoWrapperWrapper}>
              <div className={classes.showSeatInfoWrapper}>{showSeatInfo}</div>
            </div>
          </div>
          <div className={classes.map}>
            <div className={classes.planTitle}>
              {planTitle} <br />
              {planName}
            </div>
            {this.state.seatsInfo !== '' &&
            typeof this.state.seatsInfo !== 'undefined' ? (
              <a
                className={classes.map_wrapper}
                onClick={() => this.onClickOpenMapModal()}
              >
                <div className={classes.expand}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                  >
                    <path
                      fill="#000847"
                      fillRule="nonzero"
                      d="M13.338.16a.442.442 0 0 0-.054.005h.002-3.853a.488.488 0 1 0 0 .976h2.735L9.095 4.215a.489.489 0 0 0 .69.691l3.074-3.073v2.734a.488.488 0 1 0 .977 0V.713a.488.488 0 0 0-.484-.553h-.015.001zM.642 8.95a.489.489 0 0 0-.482.489v3.916c0 .015 0 .029.002.042v-.002.008-.001c.001.014.004.027.006.039v-.003a.51.51 0 0 0 .002.017v-.003a.49.49 0 0 0 .046.128v-.003l.006.014v-.003a.527.527 0 0 0 .02.033L.24 13.62a.127.127 0 0 0 .007.01H.246a.495.495 0 0 0 .094.1v.001l.005.004c.08.064.181.102.291.105h3.926a.488.488 0 1 0 0-.977H1.827L4.9 9.79a.487.487 0 0 0-.34-.839h-.015a.488.488 0 0 0-.335.148l-3.074 3.074V9.445v-.006a.488.488 0 0 0-.488-.489H.64h.002z"
                    />
                  </svg>
                </div>

                <SeatingMap
                  seatsInfo={this.state.seatsInfo}
                  seatInfoSelectedKey={this.state.seatInfoSelectedKey}
                  hoveredItem={this.state.seatInfoHoveredKey}
                />
              </a>
            ) : (
              <div
                className={cx(
                  classes.loader__wrapper,
                  this.state.loaderShow ? classes.loader__wrapper__active : ''
                )}
              >
                <div className={classes.loader}>
                  <Spinner />
                </div>
              </div>
            )}
          </div>
          <div className={classes.thirdCol}>
            {switchPlanMsg !== '' ? (
              <div
                className={classes.differentPlan}
                onClick={() => this.onClickDifferentPlan()}
              >
                {/* <div className={classes.differentPlanTitle}>
                  Looking for a different plan
                </div> */}
                <div className={classes.blueButton}>Switch Your Schedule</div>
                <div className={classes.description}>{switchPlanMsg}</div>
                {/* <p className={classes.disclaimer}>
                  Ticket brokers are not eligible for this offer.
                </p> */}
              </div>
            ) : (
              ''
            )}
            <div className={classes.premiumSales}>
              <a href="https://google.com" className={classes.blueButton}>
                Interested in Premium Seats?
              </a>
              <div className={classes.description}>
                Click here to chat with a Nationals representative about Premium
                seating options with all-inclusive food & beverage and access to
                exclusive indoor club areas
              </div>
              {/* <p className={classes.disclaimer}>
                Ticket brokers are not eligible for this offer.
              </p> */}
            </div>
            <div className={classes.ada}>
              <a
                href={adaLink}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.blueButton}
              >
                Interested in ADA Seats?
              </a>
              <div className={classes.description}>
                Click here to view ADA seating options
              </div>

              {/* <p className={classes.disclaimer}>
                Ticket brokers are not eligible for this offer.
              </p> */}
            </div>
          </div>
        </div>
        <FormController
          backTo={ROUTES.PAGE_5_LINK}
          nextTo="checkout"
          startOver={ROUTES.PAGE_1_LINK}
          showBack={true}
          showStartOver={true}
          showSkip={false}
          showSubmit={true}
          disableSubmit={this.state.seatInfoSelectedKey === ''}
        />

        <SelectNumOfSeatsModal
          onClickNumOfSeatItem={this.onClickNumOfSeatItem}
          numOfSeatsSelected={this.state.numOfSeats}
          numOfSeatsModalConfirm={this.state.numOfSeatsModalConfirm}
          onClickNumOfSeatModalConfirm={this.onClickNumOfSeatModalConfirm}
          formStyle={this.props.formStyle}
        />
        <SeatMapModal
          showMapModal={this.state.showMapModal}
          seatsInfo={this.state.seatsInfo}
          seatInfoSelectedKey={this.state.seatInfoSelectedKey}
          hoveredItem={this.state.seatInfoHoveredKey}
          onClickCloseMapModal={this.onClickCloseMapModal}
        />
      </div>
    ) : (
      <div className={classes.errorMsg}>Please select the correct plan</div>
    );
  }
}

export default FormSelectYourSeat;
