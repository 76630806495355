export const PAGE_1_LINK = '/';
export const PAGE_2_LINK = '/style-of-plan';
export const PAGE_3_LINK = '/size-of-plan';
export const PAGE_4_LINK = '/benefits';
export const PAGE_5_LINK = '/recommended-plan';
export const PAGE_6_LINK = '/select-your-seats';
export const PAGE_7_LINK = '/checkout';

export const PAGE_1_NAME = 'Start';
export const PAGE_2_NAME = 'Style of plan';
export const PAGE_3_NAME = 'Size of plan';
export const PAGE_4_NAME = 'Benefits';
export const PAGE_5_NAME = 'Recommendation';
export const PAGE_6_NAME = 'Select Your Seats';
export const PAGE_7_NAME = 'checkout';

export const PAGE_1_KEY = 'formStart';
export const PAGE_2_KEY = 'formStyle';
export const PAGE_3_KEY = 'formSize';
export const PAGE_4_KEY = 'formBenefits';
export const PAGE_5_KEY = 'formChoose';

export const PAGE_1_CHOICES_1 = 'single';
export const PAGE_1_CHOICES_2 = 'large';
export const PAGE_1_CHOICES_3 = 'multiple';

export const PAGE_2_CHOICES_1 = 'classic';
export const PAGE_2_CHOICES_2 = 'flexible';
export const PAGE_2_CHOICES_3 = 'ballpark';

export const PAGE_3_CHOICES_1 = 'eightyClassic';
export const PAGE_3_CHOICES_2 = 'fortyClassic';
export const PAGE_3_CHOICES_3 = 'twentyClassic';
export const PAGE_3_CHOICES_4 = 'tenClassic';
export const PAGE_3_CHOICES_5 = 'fiveClassic';
export const PAGE_3_CHOICES_6 = 'eightyFlexible';
export const PAGE_3_CHOICES_7 = 'fortyFlexible';
export const PAGE_3_CHOICES_8 = 'twentyFlexible';
export const PAGE_3_CHOICES_9 = 'ballPark';

export const PAGE_4_BENEFIT_CAT_1 = 'exclusivity';
export const PAGE_4_BENEFIT_CAT_2 = 'savings';
export const PAGE_4_BENEFIT_CAT_3 = 'convenience';
export const PAGE_4_BENEFIT_CAT_4 = 'rewards';

export const PAGE_5_CHOICES_1 = 'recommend';
export const PAGE_5_CHOICES_2 = 'upsell';
export const PAGE_5_CHOICES_3 = 'downsell';
