import React, { Component } from 'react';
import classes from './PersonalForm.module.scss';
import cx from 'classnames';
import 'react-dropdown/style.css';

import cityIcon from '../../../assets/images/icons/city-icon.png';
import zipIcon from '../../../assets/images/icons/zip-icon.png';

class PersonalForm extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.props.onChangePersonalForm(this.state);
    }
  }
  state = {
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    state: '',
    city: '',
    zip: ''
  };

  updateInputValue = (e, key) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  render() {
    return (
      <div className={classes.PersonalForm}>
        <div className={classes.formRow}>
          <div className={classes.formHalf}>
            <i
              className={cx(classes.formIcon, 'fa fa-user')}
              aria-hidden="true"
            ></i>
            <input
              name="firstName"
              type="text"
              placeholder="First Name"
              maxLength="30"
              onChange={e => this.updateInputValue(e)}
            />
          </div>
          <div className={classes.formHalf}>
            <i
              className={cx(classes.formIcon, 'fa fa-user')}
              aria-hidden="true"
            ></i>
            <input
              name="lastName"
              type="text"
              placeholder="Last Name"
              maxLength="30"
              value={this.state.lastName}
              onChange={e => this.updateInputValue(e)}
            />
          </div>
        </div>
        <div className={classes.formRow}>
          <div className={classes.formHalf}>
            <i
              className={cx(classes.formIcon, 'fa fa-envelope')}
              aria-hidden="true"
            ></i>
            <input
              name="email"
              type="email"
              placeholder="Email Address"
              value={this.state.email}
              onChange={e => this.updateInputValue(e)}
            />
          </div>
          <div className={classes.formHalf}>
            <i
              className={cx(classes.formIcon, 'fa fa-map-marker')}
              aria-hidden="true"
            ></i>
            <input
              name="address"
              type="text"
              placeholder="Address"
              maxLength="120"
              value={this.state.address}
              onChange={e => this.updateInputValue(e)}
            />
          </div>
        </div>
        <div className={classes.formRow}>
          <div className={classes.formHalf}>
            <i className={cx(classes.formIcon)} aria-hidden="true">
              <img src={cityIcon} alt="" />
            </i>
            <input
              name="city"
              type="text"
              placeholder="City"
              maxLength="120"
              value={this.state.city}
              onChange={e => this.updateInputValue(e)}
            />
          </div>
          <div className={classes.formHalf}>
            <i className={cx(classes.formIcon)} aria-hidden="true">
              <img src={zipIcon} alt="" />
            </i>
            <input
              name="zip"
              type="text"
              placeholder="ZIP Code"
              maxLength="5"
              value={this.state.zip}
              onChange={e => this.updateInputValue(e)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PersonalForm;
