import React, { Component } from 'react';
import FormTitle from '../FormTitle/FormTitle';
import * as ROUTES from '../../constants/routes';
import FormController from '../../components/FormController/FormController';
import classes from './FormSizeOfPlan.module.scss';

import PlanContent from './PlanContent/PlanContent';

class FormSizeOfPlan extends Component {
  componentDidMount() {
    this.setState({ viewPlan: this.props[ROUTES.PAGE_3_KEY] });

    if (this.props.formStart === '') {
      this.props.history.push('/');
    }
    if (this.props.formStyle === 'ballpark') {
      this.props.history.push('style-of-plan');
    }

    // if (typeof this.props[ROUTES.PAGE_3_KEY] === 'undefined' || this.props[ROUTES.PAGE_3_KEY] === ''){
    //     switch (this.props[ROUTES.PAGE_2_KEY]) {
    //         case ROUTES.PAGE_2_CHOICES_1:
    //             this.setState({ viewPlan: ROUTES.PAGE_3_CHOICES_1 });
    //             this.props.updateSelectedChoices(ROUTES.PAGE_3_KEY, ROUTES.PAGE_3_CHOICES_1);
    //             break;
    //         case ROUTES.PAGE_2_CHOICES_2:
    //             this.setState({ viewPlan: ROUTES.PAGE_3_CHOICES_6 });
    //             this.props.updateSelectedChoices(ROUTES.PAGE_3_KEY, ROUTES.PAGE_3_CHOICES_6 );
    //             break;
    //         case ROUTES.PAGE_2_CHOICES_3:
    //             this.setState({ viewPlan: ROUTES.PAGE_3_CHOICES_9 });
    //             this.props.updateSelectedChoices(ROUTES.PAGE_3_KEY, ROUTES.PAGE_3_CHOICES_9);
    //             break;
    //         default:
    //         // code block
    //     }
    // }else{
    //     this.setState({ viewPlan: this.props[ROUTES.PAGE_3_KEY] });
    // }
  }
  state = {
    planChoices: {
      [ROUTES.PAGE_3_CHOICES_1]: {
        numOfGames: '82',
        price: '$1,476',
        show: [ROUTES.PAGE_2_CHOICES_1, ROUTES.PAGE_2_CHOICES_3]
      },
      [ROUTES.PAGE_3_CHOICES_2]: {
        numOfGames: '42',
        price: '$840',
        show: [ROUTES.PAGE_2_CHOICES_1, ROUTES.PAGE_2_CHOICES_2]
      },
      [ROUTES.PAGE_3_CHOICES_3]: {
        numOfGames: '20',
        price: '$440',
        show: [ROUTES.PAGE_2_CHOICES_1, ROUTES.PAGE_2_CHOICES_2]
      },
      [ROUTES.PAGE_3_CHOICES_4]: {
        numOfGames: '10',
        price: '$230',
        show: [ROUTES.PAGE_2_CHOICES_1, ROUTES.PAGE_2_CHOICES_2]
      },
      [ROUTES.PAGE_3_CHOICES_5]: {
        numOfGames: '5',
        price: '$165',
        show: [ROUTES.PAGE_2_CHOICES_1]
      }
    },
    viewPlan: '',
    viewPlanActive: true
  };
  onClickSizeOfPlanChoice = plan => {
    this.setState(state => ({ viewPlan: plan, disableSubmit: false }));

    this.props.updateSelectedChoices(ROUTES.PAGE_3_KEY, plan);
  };

  render() {
    let planSizeValues = {
      classic: [
        {
          numOfGames: '82',
          gameFrequency: 'Every Game',
          priceRange: '$1,476',
          avgSavings: '20%',
          concession: '30%',
          numOfSeats: '12',
          postSeason: 'Guaranteed',
          planChoice: ROUTES.PAGE_3_CHOICES_1
        },
        {
          numOfGames: '42',
          gameFrequency: 'Every Other Game',
          priceRange: '$840',
          avgSavings: '15%',
          concession: '20%',
          numOfSeats: '9',
          postSeason: 'Guaranteed',
          planChoice: ROUTES.PAGE_3_CHOICES_2
        },
        {
          numOfGames: '22',
          gameFrequency: 'About 1 Game per Series',
          priceRange: '$462',
          avgSavings: '10%',
          concession: '15%',
          numOfSeats: '7',
          postSeason: 'Guaranteed',
          planChoice: ROUTES.PAGE_3_CHOICES_3
        },
        {
          numOfGames: '10',
          gameFrequency: 'About 1 Game per Homestand',
          priceRange: '$220',
          avgSavings: '10%',
          concession: '10%',
          numOfSeats: '6',
          postSeason: 'Pre-sale',
          planChoice: ROUTES.PAGE_3_CHOICES_4
        },
        {
          numOfGames: '5',
          gameFrequency: 'About 1 Game per Month',
          priceRange: '$115',
          avgSavings: '5%',
          concession: '5%',
          numOfSeats: '5',
          postSeason: 'Pre-sale',
          planChoice: ROUTES.PAGE_3_CHOICES_5
        }
      ],
      flexible: [
        {
          numOfGames: '80',
          gameFrequency: '',
          priceRange: '$1,600',
          avgSavings: '15%',
          concession: '15%',
          numOfSeats: '7',
          postSeason: 'Guaranteed',
          planChoice: ROUTES.PAGE_3_CHOICES_6
        },
        {
          numOfGames: '40',
          gameFrequency: '',
          priceRange: '$840',
          avgSavings: '10%',
          concession: '15%',
          numOfSeats: '7',
          postSeason: 'Pre-sale',
          planChoice: ROUTES.PAGE_3_CHOICES_7
        },
        {
          numOfGames: '20',
          gameFrequency: '',
          priceRange: '$440',
          avgSavings: '10%',
          concession: '10%',
          numOfSeats: '6',
          postSeason: 'Pre-sale',
          planChoice: ROUTES.PAGE_3_CHOICES_8
        }
      ],
      ballpark: [
        {
          numOfGames: '4',
          gameFrequency: '',
          priceRange: '$4',
          avgSavings: '10%',
          concession: '10%',
          numOfSeats: '',
          postSeason: 'Standing Room Access',
          planChoice: ROUTES.PAGE_3_CHOICES_9
        }
      ]
    };
    let styleOfPlanSelected = planSizeValues[this.props[ROUTES.PAGE_2_KEY]];

    return (
      <div>
        <div>
          <FormTitle title="Choose the size of your plan" />
          {this.props[ROUTES.PAGE_2_KEY] !== '' ? (
            <>
              <PlanContent
                styleOfPlanSelectedName={this.props[ROUTES.PAGE_2_KEY]}
                styleOfPlanSelected={styleOfPlanSelected}
                sizeOfPlanSelected={this.state.viewPlan}
                onClickSizeOfPlanChoice={this.onClickSizeOfPlanChoice}
              />
              <FormController
                backTo={ROUTES.PAGE_2_LINK}
                nextTo={ROUTES.PAGE_4_LINK}
                startOver={ROUTES.PAGE_1_LINK}
                showBack
                showStartOver
                showSkip={false}
                showSubmit
                disableSubmit={this.state.viewPlan === ''}
              />
            </>
          ) : (
            <div className={classes.errorMsg}>No Selected Plan Selected</div>
          )}
        </div>
      </div>
    );
  }
}

export default FormSizeOfPlan;
