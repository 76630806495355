import React from 'react';
import classes from './Spinner.module.scss';
const Spinner = () => {
  return (
    <div className={classes['circle-loader__wrapper']}>
      <div className={classes['circle-loader']}>
        <div className={classes['checkmark draw']}></div>
      </div>
    </div>
  );
};

export default Spinner;
