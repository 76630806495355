import React, { Component } from 'react';
import { Braintree, HostedField } from 'react-braintree-fields';
import cx from 'classnames';
import classes from './CheckoutForm.module.scss';
import cvvIcon from '../../../assets/images/checkout/cvvIcon.png';
class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.numberField = React.createRef();
    this.braintree = React.createRef();
    [
      'onError',
      'getToken',
      'onCardTypeChange',
      'onAuthorizationSuccess'
    ].forEach(prop => (this[prop] = this[prop].bind(this)));
  }

  state = {};

  onError(error) {
    this.setState({ error });
  }

  getToken = () => {
    const token = this.tokenize();
    token
      .then(token => {
        this.props.onClickCheckoutSubmit('Success', token);
        return this.setState({ token, error: null });
      })
      .catch(error => {
        this.props.onClickCheckoutSubmit('Error', error);
        return this.setState({ token: null, error });
      });
  };

  onCardTypeChange({ cards }) {
    if (1 === cards.length) {
      const [card] = cards;

      this.setState({ card: card.type });

      if (card.code && card.code.name) {
        this.cvvField.setPlaceholder(card.code.name);
      } else {
        this.cvvField.setPlaceholder('CVV');
      }
    } else {
      this.setState({ card: '' });
      this.cvvField.setPlaceholder('CVV');
    }
  }

  state = {
    numberFocused: false
  };

  componentDidMount() {
    this.setState({ authorization: 'sandbox_g42y39zw_348pk9cgf3bgyw2b' });
  }

  renderResult(title, obj) {
    //this.props.onClickCheckoutSubmit('Token', obj)
    // if (!obj) { return null; }
    // return (
    //     <div>
    //         <b>{title}:</b>
    //         <pre>{JSON.stringify(obj, null, 4)}</pre>
    //     </div>
    // );
    //this.props.onClickCheckoutSubmit(title, obj);
  }

  onAuthorizationSuccess() {
    this.numberField.current.focus();
  }

  render() {
    return (
      <>
        <div className={classes.checkoutForm}>
          <Braintree
            ref={this.braintree}
            authorization={this.props.authorization}
            onAuthorizationSuccess={this.onAuthorizationSuccess}
            onError={this.onError}
            getTokenRef={t => (this.tokenize = t)}
            onCardTypeChange={this.onCardTypeChange}
            styles={{
              input: {
                'font-size': '10px',
                'font-family': 'sans-serif',
                color: '#7d6b6b',
                'letter-spacing': '0.5px',
                height: '30px'
              },
              ':focus': {
                color: 'black'
              }
            }}
          >
            <div>
              <div className={classes.formRow}>
                <div className={classes.formFull}>
                  <i
                    className={cx(classes.formIcon, 'fa fa-credit-card')}
                    aria-hidden="true"
                  ></i>
                  <HostedField
                    type="number"
                    onBlur={() => this.setState({ numberFocused: false })}
                    onFocus={() => this.setState({ numberFocused: true })}
                    className={this.state.numberFocused ? 'focused' : ''}
                    placeholder="Credit Card"
                    ref={this.numberField}
                  />
                </div>
              </div>
              <div className={classes.formRow}>
                <div className={classes.formRowHalf}>
                  <i
                    className={cx(classes.formIcon, 'fa fa-calendar')}
                    aria-hidden="true"
                  ></i>
                  <HostedField type="expirationDate" placeholder="MMYYYY" />
                </div>

                <div className={classes.formRowHalf}>
                  <img
                    className={classes.cvvIcon}
                    src={cvvIcon}
                    alt="CVV Icon"
                  />
                  <HostedField
                    type="cvv"
                    placeholder="CVV"
                    ref={cvvField => {
                      this.cvvField = cvvField;
                    }}
                  />
                </div>
              </div>
            </div>
          </Braintree>
          <div
            className={cx(
              classes.formError,
              this.props.validation === false ? classes.active : ''
            )}
          >
            {this.props.validationErrorMsg}
          </div>
        </div>
        <div className={classes.submitForm}>
          <button onClick={() => this.getToken()}>Purchase</button>
        </div>

        <p className={classes.disclaimer}>
          Ticket brokers are not eligible for this offer.
        </p>
      </>
    );
  }
}

export default CheckoutForm;
