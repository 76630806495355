import React from 'react';
import classesM from './ScheduleSelection/ScheduleSelection.module.scss';
import classes from './PreviewPlan.module.scss';
import cx from 'classnames';
import Spinner from '../Loader/Spinner';

const PreviewPlan = props => {
  return (
    <div
      className={cx(
        classes.modal,
        props.showPreviewPlan ? classes.modalActive : ''
      )}
    >
      <Spinner />
      {/* <div className={cx(classes.scheduleSelection, classes.loader)}> */}
      {/* <div className={classes['lds-ring']}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div> */}
      {/* <Spinner /> */}
      {/* </div> */}
    </div>
  );
};

export default PreviewPlan;
