import React from 'react';
import FormTitle from '../FormTitle/FormTitle';
import FormStartBox from './FormStartBox/FormStartBox';
import classes from './FormStart.module.scss';
import singleTix from '../../assets/images/singleTix.png';
import largeGroupTix from '../../assets/images/largeGroupTix.png';
import packageTix from '../../assets/images/packageTix.png';
import * as CONSTANT from '../../constants/routes';
import * as ROUTES from '../../constants/routes';
import FormController from '../../components/FormController/FormController';

const formStart = props => {
  return (
    <div>
      <FormTitle title="Looking For Nationals Tickets?" />
      <div className={classes.FormStartBox}>
        <FormStartBox
          icon={packageTix}
          iconStyle={{ maxWidth: '61px' }}
          title="Tickets to Multiple Games"
          description="TicketFinder can help you customize a plan with unbeatable pricing and exclusive benefits."
          buttonName="Select"
          buttonLink="style-of-plan"
          externalLink={false}
          updateSelectedChoices={() =>
            props.updateSelectedChoices(
              CONSTANT.PAGE_1_KEY,
              CONSTANT.PAGE_1_CHOICES_3
            )
          }
        />
        <FormStartBox
          icon={largeGroupTix}
          iconStyle={{ maxWidth: '82px' }}
          title="Tickets for a Large Group"
          description="For groups of 13 or more: the bigger your group, the bigger your discount."
          buttonName="Select"
          buttonLink="ticket-large-group"
          externalLink={false}
          updateSelectedChoices={() =>
            props.updateSelectedChoices(
              CONSTANT.PAGE_1_KEY,
              CONSTANT.PAGE_1_CHOICES_2
            )
          }
        />

        <FormStartBox
          icon={singleTix}
          iconStyle={{ maxWidth: '49px' }}
          title="Tickets to a Single Game"
          description="Choose your game from a season full of exciting giveaways, theme games, and special promotions."
          buttonName="Available in 2020"
          buttonLink="https://www.mlb.com/nationals/tickets/single-game-tickets"
          externalLink={true}
          updateSelectedChoices={() =>
            props.updateSelectedChoices(
              CONSTANT.PAGE_1_KEY,
              CONSTANT.PAGE_1_CHOICES_1
            )
          }
        />
      </div>
      <FormController
        backTo={ROUTES.PAGE_1_LINK}
        nextTo={ROUTES.PAGE_3_LINK}
        startOver={ROUTES.PAGE_1_LINK}
        showBack={false}
        showStartOver={false}
        showSkip={false}
        showSubmit={false}
      />
    </div>
  );
};

export default formStart;
