import React from 'react';
import classes from './FormStyleOfPlanBox.module.scss';
import perksIcon from '../../../assets/images/perksIcon.png';
import { NavLink } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';

const formStyleOfPlanBox = props => {
  let perks = [];
  let navlink = '';
  if (props.perksLevel === '1') {
    navlink = ROUTES.PAGE_4_LINK;
  } else {
    navlink = props.buttonLink;
  }
  for (let j = 0; j < props.perksLevel; j++) {
    perks.push(<img src={perksIcon} alt="Perks Level Icon" key={j} />);
  }

  return (
    <NavLink
      to={navlink}
      className={classes.FormStyleOfPlanBoxWrapper}
      onClick={props.updateSelectedChoices}
    >
      <div className={classes.FormStyleOfPlanBox}>
        <div className={classes._FormStyleOfPlanBox}>
          <img
            src={props.icon}
            alt={props.title + ' Icon'}
            style={props.iconStyle}
          />
          <div className={classes.title}>{props.title}</div>
          <div className={classes.startingPrice}>{props.startingPrice}</div>
          <div className={classes.description}>{props.description}</div>
          <div className={classes.button}>{props.buttonName}</div>
        </div>
      </div>
      <div className={classes.FormStyleOfPlanBoxPerks}>
        <div className={classes.title}>Benefits & Seating Location</div>
        <div className={classes.perksWrapper}>{perks}</div>
      </div>
    </NavLink>
  );
};

export default formStyleOfPlanBox;
