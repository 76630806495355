import React, { Component } from 'react';
import FormTitle from '../FormTitle/FormTitle';
import FormStyleOfPlanBox from './FormStyleOfPlanBox/FormStyleOfPlanBox';
import classes from './FormStyleOfPlan.module.scss';
import classicPlan from '../../assets/images/classicPlan.png';
import flexiblePlan from '../../assets/images/flexiblePlan.png';
import ballparkAccess from '../../assets/images/ballparkAccess.png';
import * as ROUTES from '../../constants/routes';
import FormController from '../../components/FormController/FormController';
import * as CONSTANT from '../../constants/routes';

class FormStyleOfPlan extends Component {
  componentDidMount() {
    if (this.props.formStart === '') {
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <div>
        <div>
          <FormTitle title="Choose the style of your plan" />
          <div className={classes.FormStyleOfPlan}>
            <FormStyleOfPlanBox
              icon={classicPlan}
              iconStyle={{ maxWidth: '50px' }}
              title="Classic Plans"
              startingPrice="Starting at $18 per game"
              description="Lock in some of the best seats in the house. These plans include our most exclusive perks."
              buttonName="Select"
              buttonLink="size-of-plan"
              perksLevel="3"
              updateSelectedChoices={() =>
                this.props.updateSelectedChoices(
                  CONSTANT.PAGE_2_KEY,
                  CONSTANT.PAGE_2_CHOICES_1
                )
              }
            />
            <FormStyleOfPlanBox
              icon={flexiblePlan}
              iconStyle={{ maxWidth: '57px' }}
              title="Flexible Plans"
              startingPrice="Starting at $20 per game"
              description="Unlock the flexibility to attend games that fit your schedule, selecting the games and seats you want as the season goes on."
              buttonName="Select"
              buttonLink="size-of-plan"
              perksLevel="2"
              updateSelectedChoices={() =>
                this.props.updateSelectedChoices(
                  CONSTANT.PAGE_2_KEY,
                  CONSTANT.PAGE_2_CHOICES_2
                )
              }
            />
            <FormStyleOfPlanBox
              icon={ballparkAccess}
              iconStyle={{ maxWidth: '57px' }}
              title="Ballpark Access"
              startingPrice="Starting at $4 per game"
              description="Enjoy the game from any Standing Room location, including bars and restaurants throughout the park."
              buttonName="Select"
              buttonLink="size-of-plan"
              perksLevel="1"
              updateSelectedChoices={() => {
                this.props.updateSelectedChoices(
                  CONSTANT.PAGE_2_KEY,
                  CONSTANT.PAGE_2_CHOICES_3
                );
              }}
            />
          </div>
        </div>
        <FormController
          backTo={ROUTES.PAGE_1_LINK}
          nextTo={ROUTES.PAGE_3_LINK}
          startOver={ROUTES.PAGE_1_LINK}
          showBack={true}
          showStartOver={false}
          showSkip={false}
          showSubmit={false}
        />
      </div>
    );
  }
}

export default FormStyleOfPlan;
