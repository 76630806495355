import React from 'react';
import TopNavigationItem from './TopNavigationItem/TopNavigationItem';
import classes from './TopNavigation.module.scss';
import * as ROUTES from '../../constants/routes';

const topNavigation = props => {
  const { selectedChoices } = props.data;
  const {
    formSize,
    formStart,
    formStyle,
    selectYourSeatsUuid,
    seatInfoSelected
  } = selectedChoices;

  return (
    <div className={classes.TopNavigation}>
      {formStart === 'multiple' && (
        <>
          <TopNavigationItem
            navName={ROUTES.PAGE_1_NAME}
            path={ROUTES.PAGE_1_LINK}
            active
          />
          <TopNavigationItem
            navName={ROUTES.PAGE_2_NAME}
            path={ROUTES.PAGE_2_LINK}
            active={formStart !== '' && formStart !== 'large'}
          />
          <TopNavigationItem
            navName={ROUTES.PAGE_3_NAME}
            path={ROUTES.PAGE_3_LINK}
            active={formStyle !== '' && formStart !== 'large'}
          />
          <TopNavigationItem
            navName={ROUTES.PAGE_4_NAME}
            path={ROUTES.PAGE_4_LINK}
            active={formSize !== '' && formStart !== 'large'}
          />
          <TopNavigationItem
            navName={ROUTES.PAGE_5_NAME}
            path={ROUTES.PAGE_5_LINK}
            active={formSize !== '' && formStart !== 'large'}
          />
          <TopNavigationItem
            navName={ROUTES.PAGE_6_NAME}
            path={ROUTES.PAGE_6_LINK}
            active={selectYourSeatsUuid !== '' && formStart !== 'large'}
          />
          <TopNavigationItem
            navName={ROUTES.PAGE_7_NAME}
            path={ROUTES.PAGE_7_LINK}
            active={seatInfoSelected !== '' && formStart !== 'large'}
          />
        </>
      )}
    </div>
  );
};

export default topNavigation;
