import React, { Component } from 'react';
import { hot } from 'react-hot-loader';

import Logo from './components/Logo/Logo';
import { BrowserRouter } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import FormBuilder from './containers/FormBuilder/FormBuilder';
import classes from './App.module.scss';

class App extends Component {
  state = {
    start: []
  };
  onUpdateState = () => {
    //this.setState({state})
  };
  render() {
    return (
      <div className={classes.TicketfinderApp}>
        <Logo />
        <div className={classes.container}>
          <BrowserRouter basename="/app">
            <Layout>
              <FormBuilder />
            </Layout>
          </BrowserRouter>
        </div>
      </div>
    );
  }
}

export default hot(module)(App);
