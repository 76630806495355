import React from 'react';
import classes from './ScheduleSelection.module.scss';
import closeIcon from '../../../assets/images/closeIcon.png';
import cx from 'classnames';
import * as ROUTES from '../../../constants/routes';

const scheduleSelection = props => {
  let scheduleSelectionValues = {
    fortyClassic: {
      header: 'Choose the 42-Game Classic Plan that fits your schedule best!',
      subHeader:
        'Before selecting your seats, select 1 of the 2 options below:',
      plan: [
        {
          title: 'Friday/Sunday Plan',
          description:
            'Includes almost every Friday & Sunday game, with midweek games filling out the rest of the schedule.',
          subDescription:
            'Plan includes Opening Day (alternate seat location).',
          uuid: 'zN6VE3n0B5'
        },
        {
          title: 'Saturday/Midweek Plan',
          description:
            'Primarily features midweek (Monday through Thursday) games, as well as most Saturday games.',
          subDescription: 'Plan includes Opening Day (same seat location).',
          uuid: 'CAFbHgRVNg'
        }
      ]
    },
    twentyClassic: {
      header: 'Choose the 22-Game Classic Plan that fits your schedule best!',
      subHeader:
        'Before selecting your seats, select 1 of the 4 options below:',
      plan: [
        {
          title: "Abe's Plan",
          description: 'Includes almost every Friday game',
          uuid: 'buTMiiX4Er'
        },
        {
          title: "George's Plan",
          description: 'Includes almost every Sunday game',
          uuid: 'lRcyJMLnKk'
        },
        {
          title: "Tom's Plan",
          description:
            'Features almost exclusively midweek (Monday through Thursday) games',
          uuid: 'TLpAWWaMkk'
        },
        {
          title: "Teddy's Plan",
          description:
            'Includes a blend of Saturday and midweek (Monday through Thursday) games',
          uuid: '9XZEeo7qMz'
        }
      ]
    },
    tenClassic: {
      header: 'Choose the 10-Game Classic Plan that fits your schedule best!',
      subHeader:
        'Before selecting your seats, select 1 of the 4 options below:',
      plan: [
        {
          title: 'Build Your Own Plan',
          description:
            'Allows you to build your own customized schedule of 10 games',
          uuid: 'buTMiiX4Er'
        },
        {
          title: 'Big Game Plan',
          description:
            'Loaded with games against 2019 Postseason teams, NL East division rivals, and interleague opponents',
          uuid: 'beInIw45nx'
        },
        {
          title: 'Sunday Plan',
          description:
            'Includes 10 Sunday games, featuring kid-friendly activities & giveaways',
          uuid: 'EJ41wkGOLk'
        },
        {
          title: 'Opening Day Plan',
          description:
            'The only 10-Game Classic Plan that includes Opening Day, in addition to a blend of weekend & midweek games',
          uuid: 'uy55P4lmFk'
        }
      ]
    },
    fiveClassic: {
      header: 'Choose the 5-Game Classic Plan that fits your schedule best!',
      subHeader:
        'Before selecting your seats, select 1 of the 4 options below:',
      plan: [
        {
          title: 'Build Your Own Plan',
          description:
            'Allows you to build your own customized schedule of 5 games',
          uuid: 'buTMiiX4Er'
        },
        {
          title: 'Bobblehead Plan',
          description: '5 games. 5 bobblehead giveaways.',
          uuid: '54C8ZCSb4s'
        },
        {
          title: 'Opening Day Plan',
          description:
            'The only 5-Game Classic Plan that includes Opening Day, in addition to a blend of weekend & midweek games',
          uuid: 'v0C11qgztN'
        },
        {
          title: 'Saturday Plan',
          description:
            'Includes 5 Saturday games, spread evenly over the course of the season',
          uuid: 'baxawZnlK1'
        }
      ]
    }
  };
  let scheduleSelectionSelect = '';
  switch (props.schedulePlan) {
    case ROUTES.PAGE_3_CHOICES_2:
      scheduleSelectionSelect =
        scheduleSelectionValues[ROUTES.PAGE_3_CHOICES_2];
      break;
    case ROUTES.PAGE_3_CHOICES_3:
      scheduleSelectionSelect =
        scheduleSelectionValues[ROUTES.PAGE_3_CHOICES_3];
      break;
    case ROUTES.PAGE_3_CHOICES_4:
      scheduleSelectionSelect =
        scheduleSelectionValues[ROUTES.PAGE_3_CHOICES_4];
      break;
    case ROUTES.PAGE_3_CHOICES_5:
      scheduleSelectionSelect =
        scheduleSelectionValues[ROUTES.PAGE_3_CHOICES_5];
      break;

    default:
      break;
  }
  let scheduleSelectionBody =
    scheduleSelectionSelect !== ''
      ? scheduleSelectionSelect.plan.map((res, index) => (
          <div key={index} className={classes.scheduleSelectionPlan}>
            <div className={classes.scheduleSelectionPlanTitle}>
              {res.title}
            </div>
            <div className={classes.scheduleSelectionPlanDescription}>
              <span>
                {res.description}
                <br />
                {res.subDescription}
              </span>

              {/* {res.subDescription && {res.subDescription}} */}
            </div>
            <div
              className={classes.scheduleSelectionButton}
              onClick={() => props.onClickScheduleSelected(res.uuid)}
            >
              Select Plan
            </div>
          </div>
        ))
      : '';

  return (
    <div
      className={cx(
        classes.modal,
        props.showScheduleSelection ? classes.modalActive : ''
      )}
    >
      <div className={classes.scheduleSelection}>
        <div className={classes.scheduleSelectionTitle}>Schedule Selection</div>
        <div className={classes.scheduleSelectionSubTitle}>
          {scheduleSelectionSelect.header}
          <br />
          {scheduleSelectionSelect.subHeader}
        </div>
        <div className={classes.scheduleSelectionPlanWrapper}>
          {scheduleSelectionBody}
        </div>

        <img
          className={classes.closeIcon}
          src={closeIcon}
          alt="Close Icon"
          onClick={() => props.onClickCloseScheduleSelection()}
        />
      </div>
    </div>
  );
};

export default scheduleSelection;
