import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import TopNavigation from '../../components/TopNavigation/TopNavigation';

import FormStart from '../../components/FormStart/FormStart';
import FormSizeOfPlan from '../../components/FormSizeOfPlan/FormSizeOfPlan';
import FormStyleOfPlan from '../../components/FormStyleOfPlan/FormStyleOfPlan';
import FormBenefits from '../../components/FormBenefits/FormBenefits';
import FormChooseMySeats from '../../components/FormChooseMySeats/FormChooseMySeats';
import FormSelectYourSeat from '../../components/FormSelectYourSeat/FormSelectYourSeat';
import Checkout from '../../components/Checkout/Checkout';
import TicketLargeGroup from '../../components/TicketLargeGroup/TicketLargeGroup';
import classes from './FormBuilder.module.scss';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import '../../Transition/fade.css';
import * as CONSTANT from '../../constants/routes';

class FormBuilder extends Component {
  state = {
    choices: {
      [CONSTANT.PAGE_1_KEY]: ['single', 'largeGroup', 'package'],
      [CONSTANT.PAGE_2_KEY]: ['classic', 'flexible', 'ballpark'],
      [CONSTANT.PAGE_3_KEY]: [],
      [CONSTANT.PAGE_4_KEY]: {},
      [CONSTANT.PAGE_5_KEY]: ['recommend', 'upsell', 'downsell']
    },
    selectedChoices: {
      [CONSTANT.PAGE_1_KEY]: '',
      [CONSTANT.PAGE_2_KEY]: '',
      [CONSTANT.PAGE_3_KEY]: '',
      [CONSTANT.PAGE_4_KEY]: {},
      [CONSTANT.PAGE_5_KEY]: '',
      // selectYourSeatsUuid: 'CAFbHgRVNg',
      // seatInfoSelected: {
      //   'buyers': ['34859'],
      //   'name': 'LF/RF Mezzanine',
      //   'price': '1428',
      //   'ps-key': '1896',
      //   'section': '1101',
      //   'tickets': '42'
      // },
      selectYourSeatsUuid: '',
      seatInfoSelected: '',
      numOfSeat: '',
      benefitSubmit: false
    }
  };
  updateSelectedChoices = (selectedChoicesKey, value) => {
    this.setState({
      selectedChoices: {
        ...this.state.selectedChoices,
        [selectedChoicesKey]: value
      }
    });
  };

  updateMultipleSelectedChoices = obj => {
    this.setState({
      selectedChoices: {
        ...this.state.selectedChoices,
        ...obj
      }
    });
  };
  render() {
    const { history } = this.props;
    return (
      <div className={classes.FormBuilder}>
        <TopNavigation data={this.state} />
        <div className={classes.content}>
          <Route
            render={({ location }) => (
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  timeout={500}
                  classNames="fade"
                  unmountOnExit
                >
                  <Switch location={location}>
                    <Route
                      path="/"
                      exact
                      render={() => (
                        <FormStart
                          updateSelectedChoices={this.updateSelectedChoices}
                        />
                      )}
                    />
                    <Route
                      path="/style-of-plan"
                      render={() => (
                        <FormStyleOfPlan
                          {...this.state.selectedChoices}
                          history={history}
                          updateSelectedChoices={this.updateSelectedChoices}
                        />
                      )}
                    />
                    <Route
                      path="/ticket-large-group"
                      render={() => (
                        <TicketLargeGroup
                          updateSelectedChoices={this.updateSelectedChoices}
                        />
                      )}
                    />
                    <Route
                      path="/size-of-plan"
                      render={() => (
                        <FormSizeOfPlan
                          {...this.state.selectedChoices}
                          history={history}
                          updateSelectedChoices={this.updateSelectedChoices}
                        />
                      )}
                    />
                    <Route
                      path="/benefits"
                      render={() => (
                        <FormBenefits
                          {...this.state.selectedChoices}
                          history={history}
                          updateSelectedChoices={this.updateSelectedChoices}
                        />
                      )}
                    />
                    <Route
                      path="/recommended-plan"
                      render={() => (
                        <FormChooseMySeats
                          {...this.state.selectedChoices}
                          updateSelectedChoices={this.updateSelectedChoices}
                          updateMultipleSelectedChoices={
                            this.updateMultipleSelectedChoices
                          }
                          history={history}
                        />
                      )}
                    />
                    {/* <Route
                    path="/choose-my-seats"
                    render={() => (
                      <FormChooseMySeats
                        {...this.state.selectedChoices}
                        updateSelectedChoices={this.updateSelectedChoices}
                        history={history}
                      />
                    )}
                  /> */}
                    <Route
                      path="/select-your-seats"
                      render={() => (
                        <FormSelectYourSeat
                          {...this.state.selectedChoices}
                          updateSelectedChoices={this.updateSelectedChoices}
                          history={history}
                        />
                      )}
                    />
                    <Route
                      path="/checkout"
                      render={() => (
                        <Checkout
                          {...this.state.selectedChoices}
                          updateSelectedChoices={this.updateSelectedChoices}
                          history={history}
                        />
                      )}
                    />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            )}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(FormBuilder);
