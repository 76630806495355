import React from 'react';
import cx from 'classnames';
import classes from './FormStartBox.module.scss';
import { NavLink } from 'react-router-dom';

const formStartBox = props => {
  let formStartContent;
  let formStartContentOutput;
  const activeLink = props.buttonName === 'Select';

  formStartContent = (
    <div className={classes._FormStartBox}>
      <img
        src={props.icon}
        alt={props.title + ' Icon'}
        style={props.iconStyle}
      />
      <div className={classes.title}>{props.title}</div>
      <div className={classes.description}>{props.description}</div>
      <div
        className={cx(classes.button, !activeLink ? classes.disable_btn : null)}
      >
        {props.buttonName}
      </div>
    </div>
  );

  formStartContentOutput = props.externalLink ? (
    activeLink ? (
      <a
        href={props.buttonLink}
        className={classes.FormStartBox}
        onClick={props.updateSelectedChoices}
      >
        {formStartContent}
      </a>
    ) : (
      <div className={classes.FormStartBox}>{formStartContent}</div>
    )
  ) : (
    <NavLink
      to={props.buttonLink}
      className={classes.FormStartBox}
      onClick={props.updateSelectedChoices}
    >
      {formStartContent}
    </NavLink>
  );

  return formStartContentOutput;
};

export default formStartBox;
