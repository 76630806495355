import React from 'react';
import FormControllerButton from './FormControllerButton/FormControllerButton';
import classes from './FormController.module.scss';
import ReactSVG from 'react-svg';
import ArrowLeft from '../../assets/images/arrowLeft.svg';
import ArrowRight from '../../assets/images/arrowRight.svg';
import StartOver from '../../assets/images/startOver.svg';

const formController = props => {
  return (
    <div className={classes.FormController}>
      <div className={classes.leftBtn}>
        {props.showBack ? (
          <FormControllerButton
            controllerType="back"
            pathTo={props.backTo}
            type="back"
          >
            <ReactSVG src={ArrowLeft} className={classes.FormButtonIcon} />
            <span> Back</span>
          </FormControllerButton>
        ) : (
          ''
        )}

        {props.showStartOver ? (
          <FormControllerButton
            controllerType="startOver"
            pathTo={props.startOver}
          >
            <ReactSVG src={StartOver} className={classes.FormButtonIcon} />
            <span> Start over</span>
          </FormControllerButton>
        ) : (
          ''
        )}
      </div>
      <div className={classes.Chatbutton}>
        <a href="#/">
          <div>
            <img
              src={require('../../assets/images/icons/icon-chat.png')}
              alt=""
            />
          </div>
          <div>
            <span>CHAT WITH A NATIONALS REPRESENTATIVE</span>
          </div>
        </a>
      </div>
      <div className={classes.rightBtn}>
        {props.showSkip ? (
          <FormControllerButton pathTo={props.nextTo} controllerType="skip">
            <p>Skip</p>
            <span> Skip this step</span>
          </FormControllerButton>
        ) : (
          ''
        )}

        {props.showSubmit ? (
          <FormControllerButton
            controllerType="submit"
            pathTo={props.nextTo}
            disableSubmit={props.disableSubmit}
          >
            <span> Submit </span>
            <ReactSVG src={ArrowRight} className={classes.FormButtonIcon} />
          </FormControllerButton>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default formController;
