import React from 'react';
import classes from './SeatNumItem.module.scss';
import cx from 'classnames';
const seatNumItem = (props) => {
    return(
        <div className={cx(classes.seatNumItem, props.seatNum === props.numOfSeatsSelected ? classes.active : '')} 
            onClick={() => props.onClickNumOfSeatItem(props.seatNum)}
        >
            {props.seatNum}
        </div>
    );
}

export default seatNumItem;