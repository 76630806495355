import React from 'react';
import classes from '../FormSizeOfPlan.module.scss';
import cx from 'classnames';

import avgSavingsIcon from '../../../assets/images/avgSavings.jpg';
import concessionIcon from '../../../assets/images/concession.png';
import seatIcon from '../../../assets/images/seat.png';
import guaranteeIcon from '../../../assets/images/guarantee.png';
import chevRight from '../../../assets/images/chevRight.png';

const planContent = props => {
  let planBody = props.styleOfPlanSelected.map((res, index) => (
    <div
      key={index}
      className={cx(
        classes.row,
        props.sizeOfPlanSelected === res.planChoice
          ? classes.planActive
          : classes.planDeactive
      )}
      onClick={() => props.onClickSizeOfPlanChoice(res.planChoice)}
    >
      <div className={cx(classes.col, classes.planChoices)}>
        <span className={classes.numOfGames}>{res.numOfGames}</span>
        <span className={classes.numOfGamesTitle}>
          {props.styleOfPlanSelectedName === 'flexible' ? 'TICKETS' : 'Games'}
        </span>
        <span className={classes.numOfGamesNote}>{res.gameFrequency}</span>
        <span className={cx(classes.numOfGamesNote, classes.red)}>
          Plans start at {res.priceRange}
        </span>
        <span className={classes.blueButton}>
          <img src={chevRight} alt="Plan Choice Icon" />
        </span>
      </div>
      <div className={cx(classes.col, classes.num, classes.values)}>
        {res.concession}
      </div>
      <div className={cx(classes.col, classes.num, classes.values)}>
        {res.avgSavings}
      </div>
      <div className={cx(classes.col, classes.text, classes.values)}>
        {res.postSeason}
      </div>
      <div className={cx(classes.col, classes.num, classes.values)}>
        {res.numOfSeats}
      </div>
    </div>
  ));
  return (
    <div className={classes.FormSizeOfPlan}>
      <div className={cx(classes.header, classes.row)}>
        <div className={cx(classes.col, classes.emptyCol)}></div>
        <div className={classes.col}>
          <span className={classes.headerIcon}>
            <img
              src={concessionIcon}
              style={{ maxWidth: '26px' }}
              alt="Avg Savings Icon"
            />
          </span>
          <span>
            Savings Off
            <br />
            Concessions & Merchandise
          </span>
        </div>

        <div className={classes.col}>
          <span className={classes.headerIcon}>
            <img
              src={avgSavingsIcon}
              style={{ maxWidth: '33px' }}
              alt="Avg Savings Icon"
            />
          </span>
          <span>
            Average Savings off
            <br />
            Single-Game Ticket Prices
          </span>
        </div>
        <div className={classes.col}>
          <span className={classes.headerIcon}>
            <img
              src={guaranteeIcon}
              style={{ maxWidth: '34px' }}
              alt="Avg Savings Icon"
            />
          </span>
          <span>
            Postseason
            <br />
            Access
          </span>
        </div>
        <div className={classes.col}>
          <span className={classes.headerIcon}>
            <img
              src={seatIcon}
              style={{ maxWidth: '34px' }}
              alt="Avg Savings Icon"
            />
          </span>
          <span>
            # Of Available
            <br />
            Seating Areas
          </span>
        </div>
      </div>
      {planBody}
    </div>
  );
};

export default planContent;
