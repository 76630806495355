import React, { Component } from 'react';
import FormTitle from '../FormTitle/FormTitle';
import FormController from '../../components/FormController/FormController';
import classes from './FormChooseMySeats.module.scss';
import PlanRecommendation from './PlanRecommendation/PlanRecommendation';
import PlanUpsell from './PlanUpsell/PlanUpsell';
import PlanDownsell from './PlanDownsell/PlanDownsell';
import ScheduleSelection from './ScheduleSelection/ScheduleSelection';
import PreviewPlan from './PreviewPlan';
import * as ROUTES from '../../constants/routes';

class FormChooseMySeats extends Component {
  componentDidMount() {
    if (this.props.formStart === '') {
      this.props.history.push('/');
    }

    // if (this.props.selectYourSeatsUuid) {
    //   switch (this.props.formSize) {
    //     case ROUTES.PAGE_3_CHOICES_2:
    //     case ROUTES.PAGE_3_CHOICES_3:
    //       this.setState({ showScheduleSelection: true });
    //       break;
    //     default:
    //       break;
    //   }
    // }

    this.setState({ showPreviewPlan: false });

    if (
      typeof this.props.history.location.state !== 'undefined' &&
      this.props[ROUTES.PAGE_3_KEY]
    ) {
      if (this.props.history.location.state.showModal === true) {
        this.setState({ showScheduleSelection: true });
      }
    }
  }
  state = {
    showScheduleSelection: false,
    showPreviewPlan: false,
    planToPreview: ''
  };
  onClickChooseMyPlansToggle = plan => {
    this.setState({ showPreviewPlan: true });

    setTimeout(() => {
      switch (plan) {
        case ROUTES.PAGE_3_CHOICES_1:
        case ROUTES.PAGE_3_CHOICES_2:
        case ROUTES.PAGE_3_CHOICES_3:
        case ROUTES.PAGE_3_CHOICES_4:
        case ROUTES.PAGE_3_CHOICES_5:
          this.props.updateMultipleSelectedChoices({
            [ROUTES.PAGE_2_KEY]: 'classic',
            [ROUTES.PAGE_3_KEY]: plan
          });
          break;
        case ROUTES.PAGE_3_CHOICES_6:
        case ROUTES.PAGE_3_CHOICES_7:
        case ROUTES.PAGE_3_CHOICES_8:
          this.props.updateMultipleSelectedChoices({
            [ROUTES.PAGE_2_KEY]: 'flexible',
            [ROUTES.PAGE_3_KEY]: plan
          });
          break;
        case ROUTES.PAGE_3_CHOICES_9:
          this.props.updateMultipleSelectedChoices({
            [ROUTES.PAGE_2_KEY]: 'ballpark',
            [ROUTES.PAGE_3_KEY]: plan
          });
          break;
        default:
          break;
      }
      this.setState({ showPreviewPlan: false });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 1000);

    this.setState({ showPreviewPlan: true });
  };
  onClickClosePreviewPlan = plan => {
    this.setState({ showPreviewPlan: false });
  };
  onClickShowScheduleSelection = () => {
    switch (this.props[ROUTES.PAGE_3_KEY]) {
      // Classic
      case ROUTES.PAGE_3_CHOICES_1:
        this.props.updateSelectedChoices('selectYourSeatsUuid', 'xa79PAlovb');
        this.props.history.push('select-your-seats');
        break;
      case ROUTES.PAGE_3_CHOICES_2:
      case ROUTES.PAGE_3_CHOICES_3:
      case ROUTES.PAGE_3_CHOICES_4:
      case ROUTES.PAGE_3_CHOICES_5:
        this.setState({ showScheduleSelection: true });
        break;
      // Flex
      case ROUTES.PAGE_3_CHOICES_6:
        this.props.updateSelectedChoices('selectYourSeatsUuid', '6WPuBrtT8C');
        this.props.history.push('select-your-seats');
        break;
      case ROUTES.PAGE_3_CHOICES_7:
        this.props.updateSelectedChoices('selectYourSeatsUuid', 'yHsZtmvU3N');
        this.props.history.push('select-your-seats');
        break;
      case ROUTES.PAGE_3_CHOICES_8:
        this.props.updateSelectedChoices('selectYourSeatsUuid', 'LHsIqghb0k');
        this.props.history.push('select-your-seats');
        break;
      //Ballpark
      case ROUTES.PAGE_3_CHOICES_9:
        this.setState({ showScheduleSelection: true });
        this.onClickScheduleSelected('soof513JuY');
        break;
      default:
        // window.location.assign('https://google.com');
        break;
    }
  };
  onClickCloseScheduleSelection = () => {
    this.setState({ showScheduleSelection: false });
  };
  onClickSwitchPlan = () => {
    switch (this.state.planToPreview) {
      case ROUTES.PAGE_3_CHOICES_1:
      case ROUTES.PAGE_3_CHOICES_2:
      case ROUTES.PAGE_3_CHOICES_3:
      case ROUTES.PAGE_3_CHOICES_4:
      case ROUTES.PAGE_3_CHOICES_5:
        this.props.updateMultipleSelectedChoices({
          [ROUTES.PAGE_2_KEY]: 'classic',
          [ROUTES.PAGE_3_KEY]: this.state.planToPreview
        });
        break;
      case ROUTES.PAGE_3_CHOICES_6:
      case ROUTES.PAGE_3_CHOICES_7:
      case ROUTES.PAGE_3_CHOICES_8:
        this.props.updateMultipleSelectedChoices({
          [ROUTES.PAGE_2_KEY]: 'flexible',
          [ROUTES.PAGE_3_KEY]: this.state.planToPreview
        });
        break;
      case ROUTES.PAGE_3_CHOICES_9:
        this.props.updateMultipleSelectedChoices({
          [ROUTES.PAGE_2_KEY]: 'ballpark',
          [ROUTES.PAGE_3_KEY]: this.state.planToPreview
        });
        break;
      default:
        break;
    }

    this.setState({ showPreviewPlan: false });
  };
  onClickScheduleSelected = uuid => {
    this.props.updateSelectedChoices('selectYourSeatsUuid', uuid);
    this.props.history.push('select-your-seats');
  };
  render() {
    let planRecommendationValues = {
      eightyClassic: {
        slug: 'eightyClassic',
        plan: '82-Game Classic Plan',
        priceRange: '$1,476 - $9,840',
        avgSavings: '20%',
        exclusivity: [
          'Postseason Guarantee',
          'Opening Day Guarantee',
          'Same Seat All Season',
          'Exclusive Events with Nationals Players',
          'Pre-sales for Postseason & Concerts',
          'Fan Rewards Points Program'
        ],
        savings: [
          'Eat Free 1 Designated Day of the Week',
          '20% average savings off single-game ticket price',
          '30% Concessions Savings',
          '30% Team Store Savings',
          '30% Discount on Additional Ticket Purchases'
        ],
        convenience: [
          'Dedicated Ballpark Entrance',
          "Unlimited exchanges for games you can't make",
          'Dedicated Nationals service representative',
          'Dedicated Concessions Lines'
        ]
      },
      fortyClassic: {
        slug: 'fortyClassic',
        plan: '42-Game Classic Plan',
        priceRange: '$840 - $3,066',
        avgSavings: '15%',
        exclusivity: [
          'Postseason Guarantee',
          'Opening Day Guarantee',
          'Same Seat All Season',
          'Exclusive Events with Nationals Players',
          'Pre-sales for Postseason & Concerts',
          'Fan Rewards Points Program'
        ],
        savings: [
          '15% average savings off single-game ticket price',
          '20% Concessions Savings',
          '20% Team Store Savings',
          '20% Discount on Additional Ticket Purchases'
        ],
        convenience: [
          'Dedicated Ballpark Entrance',
          "Exchange tickets for games you can't make",
          'Dedicated Nationals service representative',
          'Dedicated Concessions Lines'
        ]
      },
      twentyClassic: {
        slug: 'twentyClassic',
        plan: '22-Game Classic Plan',
        priceRange: '$462 - $1,452',
        avgSavings: '10%',
        exclusivity: [
          'Postseason Guarantee',
          'Opening Day Guarantee',
          'Same Seat All Season',
          'Exclusive Events with Nationals Players',
          'Pre-sales for Postseason & Concerts',
          'Fan Rewards Points Program'
        ],
        savings: [
          '10% average savings off single-game ticket price',
          '15% Concessions Savings',
          '15% Team Store Savings',
          '15% Discount on Additional Ticket Purchases'
        ],
        convenience: [
          'Dedicated Ballpark Entrance',
          "Exchange tickets for games you can't make",
          'Dedicated Nationals service representative',
          'Dedicated Concessions Lines'
        ]
      },
      tenClassic: {
        slug: 'tenClassic',
        plan: '10-Game Classic Plan',
        priceRange: '$220 - $580',
        avgSavings: '10%',
        exclusivity: [
          'Postseason pre-sale',
          'Opening day option',
          'Pre-sales for big games & concerts',
          'Fan Rewards Points Program'
        ],
        savings: [
          '10% average savings off single-game ticket price',
          '10% Concessions Savings',
          '10% Team Store Savings',
          '10% Discount on Additional Ticket Purchases'
        ],
        convenience: [
          'Build my own schedule',
          'Dedicated Nationals service representative'
        ]
      },
      fiveClassic: {
        slug: 'fiveClassic',
        plan: '5-Game Classic Plan',
        priceRange: '$115 - $300',
        avgSavings: '5%',
        exclusivity: [
          'Postseason pre-sale',
          'Opening day option',
          'Pre-sales for big games & concerts',
          'Fan Rewards Points Program'
        ],
        savings: [
          '5% average savings off single-game ticket price',
          '5% Concessions Savings',
          '5% Team Store Savings',
          '5% Discount on Additional Ticket Purchases'
        ],
        convenience: ['Build my own schedule']
      },
      eightyFlexible: {
        slug: 'eightyFlexible',
        plan: '80-Ticket Flexible Plan',
        priceRange: '$1,600 - $5,840',
        avgSavings: '15%',
        exclusivity: [
          'Postseason Guarantee',
          'Opening day option',
          'Exclusive Events with Nationals Players',
          'Pre-sales for Postseason & Concerts',
          'Fan Rewards Points Program'
        ],
        savings: [
          '15% average savings off single-game ticket price',
          '15% Concessions Savings',
          '15% Team Store Savings',
          '15% Discount on Additional Ticket Purchases'
        ],
        convenience: [
          'Build my own schedule',
          'Dedicated Ballpark Entrance',
          'Dedicated Nationals service representative',
          'Dedicated Concessions Lines'
        ]
      },
      fortyFlexible: {
        slug: 'fortyFlexible',
        plan: '40-Ticket Flexible Plan',
        priceRange: '$840 - $3,000',
        avgSavings: '10%',
        exclusivity: [
          'Postseason pre-sale',
          'Opening day pre-sale',
          'Exclusive Events with Nationals Players',
          'Pre-sales for big games & concerts',
          'Fan Rewards Points Program'
        ],
        savings: [
          '10% average savings off single-game ticket price',
          '15% Concessions Savings',
          '15% Team Store Savings',
          '15% Discount on Additional Ticket Purchases'
        ],
        convenience: [
          'Build my own schedule',
          'Dedicated Ballpark Entrance',
          'Dedicated Nationals service representative',
          'Dedicated Concessions Lines'
        ]
      },
      twentyFlexible: {
        slug: 'twentyFlexible',
        plan: '20-Ticket Flexible Plan',
        priceRange: '$440 - $1,520',
        avgSavings: '10%',
        exclusivity: [
          'Postseason pre-sale',
          'Opening day pre-sale',
          'Exclusive Events with Nationals Players',
          'Pre-sales for big games & concerts',
          'Fan Rewards Points Program'
        ],
        savings: [
          '10% average savings off single-game ticket price',
          '10% Concessions Savings',
          '10% Team Store Savings',
          '10% Discount on Additional Ticket Purchases'
        ],
        convenience: [
          'Build my own schedule',
          'Dedicated Nationals service representative'
        ]
      },
      eightyBallpark: {
        slug: 'eightyBallpark',
        plan: '82-Game Ballpark Access Pass',
        priceRange: 'Price: $328',
        avgSavings: '$4',
        exclusivity: [
          'Postseason pre-sale',
          'Opening Day Guarantee',
          'Pre-sales for big games & concerts',
          'Fan Rewards Points Program'
        ],
        savings: [
          'Savings off single-game ticket price',
          '10% Concessions Savings',
          '10% Team Store Savings',
          '10% Discount on Additional Ticket Purchases'
        ],
        convenience: ['Dedicated Nationals service representative']
      }
    };
    let planUpsellValues = {
      eightyClassic: {
        slug: 'eightyClassic',
        plan: '82-Game Classic Plan',
        priceRange: '$1,476',
        avgSavings: '20%',
        benefits: [
          'Eat Free 1 Designated Day of the Week',
          "Unlimited exchanges for games you can't make",
          'Bigger savings off tickets, concessions & merchandise'
        ]
      },
      fortyClassic: {
        slug: 'fortyClassic',
        plan: '42-Game Classic Plan',
        priceRange: '$840',
        avgSavings: '15%',
        benefits: ['Bigger savings off tickets, concessions & merchandise']
      },
      twentyClassic: {
        slug: 'twentyClassic',
        plan: '22-Game Classic Plan',
        priceRange: '$462',
        avgSavings: '10%',
        benefits: [
          "Exchange tickets for games you can't make",
          'Dedicated Ballpark Entrance',
          'Dedicated Concessions Lines'
        ]
      },
      tenClassic: {
        slug: 'tenClassic',
        plan: '10-Game Classic Plan',
        priceRange: '$220',
        avgSavings: '10%',
        benefits: [
          'Double the savings off tickets, concessions & merchandise',
          'Dedicated Nationals service representative'
        ]
      },
      fortyFlexible: {
        slug: 'fortyFlexible',
        plan: '40-Ticket Flexible Plan',
        priceRange: '$840',
        avgSavings: '10%',
        benefits: [
          'Dedicated Ballpark Entrance',
          'Dedicated Concessions Lines',
          'Bigger savings off concessions & merchandise'
        ]
      },
      eightyFlexible: {
        slug: 'eightyFlexible',
        plan: '80-Ticket Flexible Plan',
        priceRange: '$1,600',
        avgSavings: '15%',
        benefits: ['Postseason Guarantee', 'Bigger ticket savings']
      },
      twentyFlexible: {
        slug: 'twentyFlexible',
        plan: '20-Ticket Flexible Plan',
        priceRange: '$440',
        avgSavings: '10%',
        benefits: [
          'Dedicated Ballpark Entrance',
          'Dedicated Concessions Lines',
          'Bigger savings off concessions & merchandise'
        ]
      }
    };
    let planDownsellValues = {
      fortyClassic: {
        slug: 'fortyClassic',
        plan: '42-Game Classic Plan',
        priceRange: '$840'
      },
      twentyClassic: {
        slug: 'twentyClassic',
        plan: '22-Game Classic Plan',
        priceRange: '$462'
      },
      tenClassic: {
        slug: 'tenClassic',
        plan: '10-Game Classic Plan',
        priceRange: '$220'
      },
      fiveClassic: {
        slug: 'fiveClassic',
        plan: '5-Game Classic Plan',
        priceRange: '$115'
      },
      singleGame: {
        slug: 'singleGame',
        plan: 'Single-Game Tickets',
        priceRange: '$11'
      },
      fortyFlexible: {
        slug: 'fortyFlexible',
        plan: '40-Ticket Flexible Plan',
        priceRange: '$840'
      },
      twentyFlexible: {
        slug: 'twentyFlexible',
        plan: '20-Ticket Flexible Plan',
        priceRange: '$440'
      },
      tenFlexible: {
        slug: 'tenFlexible',
        plan: '10-Game Flexible Plan',
        priceRange: '$220'
      }
    };

    let planRecommendationSelected = '';
    let planUpsellValuesSelected = '';
    let planDownsellValuesSelected = '';

    if (
      typeof this.props[ROUTES.PAGE_3_KEY] !== 'undefined' ||
      this.props[ROUTES.PAGE_3_KEY] !== ''
    ) {
      switch (this.props[ROUTES.PAGE_3_KEY]) {
        case ROUTES.PAGE_3_CHOICES_1:
          planRecommendationSelected =
            planRecommendationValues['eightyClassic'];
          planUpsellValuesSelected = '';
          planDownsellValuesSelected = planDownsellValues['fortyClassic'];
          break;
        case ROUTES.PAGE_3_CHOICES_2:
          planRecommendationSelected = planRecommendationValues['fortyClassic'];
          planUpsellValuesSelected = planUpsellValues['eightyClassic'];
          planDownsellValuesSelected = planDownsellValues['twentyClassic'];
          break;
        case ROUTES.PAGE_3_CHOICES_3:
          planRecommendationSelected =
            planRecommendationValues['twentyClassic'];
          planUpsellValuesSelected = planUpsellValues['fortyClassic'];
          planDownsellValuesSelected = planDownsellValues['tenClassic'];
          break;
        case ROUTES.PAGE_3_CHOICES_4:
          planRecommendationSelected = planRecommendationValues['tenClassic'];
          planUpsellValuesSelected = planUpsellValues['twentyClassic'];
          planDownsellValuesSelected = planDownsellValues['fiveClassic'];
          break;
        case ROUTES.PAGE_3_CHOICES_5:
          planRecommendationSelected = planRecommendationValues['fiveClassic'];
          planUpsellValuesSelected = planUpsellValues['tenClassic'];
          planDownsellValuesSelected = planDownsellValues['singleGame'];
          break;
        case ROUTES.PAGE_3_CHOICES_6:
          planRecommendationSelected =
            planRecommendationValues['eightyFlexible'];
          planUpsellValuesSelected = planUpsellValues['eightyClassic'];
          planDownsellValuesSelected = planDownsellValues['fortyFlexible'];
          break;
        case ROUTES.PAGE_3_CHOICES_7:
          planRecommendationSelected =
            planRecommendationValues['fortyFlexible'];
          planUpsellValuesSelected = planUpsellValues['eightyFlexible'];
          planDownsellValuesSelected = planDownsellValues['twentyFlexible'];
          break;
        case ROUTES.PAGE_3_CHOICES_8:
          planRecommendationSelected =
            planRecommendationValues['twentyFlexible'];
          planUpsellValuesSelected = planUpsellValues['fortyFlexible'];
          planDownsellValuesSelected = planDownsellValues['fiveClassic'];
          break;
        case ROUTES.PAGE_3_CHOICES_9:
          planRecommendationSelected =
            planRecommendationValues['eightyBallpark'];
          planUpsellValuesSelected = planUpsellValues['fortyFlexible'];
          planDownsellValuesSelected = planDownsellValues['tenClassic'];
          break;
        default:
      }
    }

    return (
      <div>
        <div>
          <FormTitle title="" />
          {planRecommendationSelected !== '' ||
          planUpsellValuesSelected !== '' ||
          planDownsellValuesSelected !== '' ? (
            <>
              <div className={classes.PlansWrapper}>
                <div className={classes.leftPlans}>
                  {planRecommendationSelected ? (
                    <>
                      <PlanRecommendation
                        planRecommendationSelected={planRecommendationSelected}
                        onClickShowScheduleSelection={
                          this.onClickShowScheduleSelection
                        }
                      />
                      <PreviewPlan
                        showPreviewPlan={this.state.showPreviewPlan}
                      />
                    </>
                  ) : (
                    ''
                  )}
                </div>
                <div className={classes.rightPlans}>
                  {planUpsellValuesSelected ? (
                    <PlanUpsell
                      planUpsellValuesSelected={planUpsellValuesSelected}
                      planRecommendationSelected={planRecommendationSelected}
                      onClickChooseMyPlansToggle={
                        this.onClickChooseMyPlansToggle
                      }
                    />
                  ) : (
                    ''
                  )}
                  {planDownsellValuesSelected ? (
                    <PlanDownsell
                      planDownsellValuesSelected={planDownsellValuesSelected}
                      onClickChooseMyPlansToggle={
                        this.onClickChooseMyPlansToggle
                      }
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <FormController
                backTo={ROUTES.PAGE_4_LINK}
                nextTo={ROUTES.PAGE_5_LINK}
                startOver={ROUTES.PAGE_1_LINK}
                showBack={true}
                showStartOver={true}
                showSkip={false}
                showSubmit={false}
              />
            </>
          ) : (
            <div className={classes.errorMsg}>Please choose a package</div>
          )}
        </div>
        <ScheduleSelection
          schedulePlan={this.props[ROUTES.PAGE_3_KEY]}
          showScheduleSelection={this.state.showScheduleSelection}
          onClickCloseScheduleSelection={this.onClickCloseScheduleSelection}
          onClickScheduleSelected={this.onClickScheduleSelected}
        />
      </div>
    );
  }
}

export default FormChooseMySeats;
