import React from 'react';
import classes from './PlanDownsell.module.scss';
import cx from 'classnames';

const planDownsell = props => (
  <div
    className={cx(
      classes.PlanDownsell,
      props.planDownsellValuesSelected.slug === 'singleGame'
        ? classes.PlanDownsellDisable
        : ''
    )}
  >
    <div className={classes.title}>Looking for a smaller plan?</div>
    {props.planDownsellValuesSelected.slug !== 'singleGame' ? (
      <>
        <div className={classes.benefits}>
          Learn more about the{' '}
          <strong>{props.planDownsellValuesSelected.plan}</strong>, which starts
          at just {props.planDownsellValuesSelected.priceRange}
        </div>
        <div
          className={cx(classes.blueButton, classes.button)}
          onClick={() =>
            props.onClickChooseMyPlansToggle(
              props.planDownsellValuesSelected['slug']
            )
          }
        >
          Switch to <span>{props.planDownsellValuesSelected.plan}</span>
        </div>
      </>
    ) : (
      <>
        <div className={classes.benefits}>
          Learn more about{' '}
          <strong>{props.planDownsellValuesSelected.plan}</strong>, which start
          at just {props.planDownsellValuesSelected.priceRange}
        </div>
        <div className={cx(classes.disableButton, classes.button)}>
          Available in 2020
        </div>
      </>
    )}
    {/* <p className={classes.disclaimer}>
      Ticket brokers are not eligible for this offer.
    </p> */}
  </div>
);

export default planDownsell;
