import React from 'react';
import classes from './PlanRecommendation.module.scss';

const planRecommendation = props => (
  <div className={classes.PlanRecommend}>
    <div className={classes.Title}>We Recommend: </div>
    <div className={classes.planTitle}>
      {props.planRecommendationSelected.plan}
    </div>
    {props.planRecommendationSelected.slug !== 'eightyBallpark' ? (
      <div className={classes.priceRange}>
        Price Range {props.planRecommendationSelected.priceRange}
        <br />
        Save <strong>{props.planRecommendationSelected.avgSavings}</strong> off
        Single-Game prices
        {/* <p className={classes.disclaimer}>
          Ticket brokers are not eligible for this offer.
        </p> */}
      </div>
    ) : (
      <div className={classes.priceRange}>
        {props.planRecommendationSelected.priceRange}
        <br />
        Only <strong>{props.planRecommendationSelected.avgSavings}</strong> per
        game
        {/* <p className={classes.disclaimer}>
          Ticket brokers are not eligible for this offer.
        </p> */}
      </div>
    )}
    <div className={classes.benefitsWrapper}>
      <div className={classes.benefitTitle}>Benefits</div>

      <div className={classes.benefitListWrapper}>
        <div className={classes.benefitsListItem}>
          <div className={classes.benefitsListItemTitle}>Exclusivity</div>
          <ul>
            {props.planRecommendationSelected.exclusivity.map((res, index) => (
              <li key={index}>{res}</li>
            ))}
          </ul>
        </div>
        <div className={classes.benefitsListItem}>
          <div className={classes.benefitsListItemTitle}>Savings</div>
          <ul>
            {props.planRecommendationSelected.savings.map((res, index) => (
              <li key={index}>{res}</li>
            ))}
          </ul>
        </div>
        <div className={classes.benefitsListItem}>
          <div className={classes.benefitsListItemTitle}>Convenience</div>
          <ul>
            {props.planRecommendationSelected.convenience.map((res, index) => (
              <li key={index}>{res}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    {/* <NavLink to="checkout" className={classes.greenButton}>Show me my seats</NavLink> */}
    <div
      className={classes.greenButton}
      onClick={() => props.onClickShowScheduleSelection()}
    >
      {props.planRecommendationSelected.slug === 'eightyBallpark'
        ? 'Buy Now'
        : 'Show me my seats'}
    </div>
  </div>
);

export default planRecommendation;
