import React from 'react';
import cx from 'classnames';
import classes from './FormBenefitsBoxItem.module.scss';

const FormBenefitsBoxItem = props => {
  const benefitsObj = props.benefits;
  let benefits = [];
  benefitsObj.map((benefitsItem, index) =>
    benefitsItem.show.includes(props.sizeOfPlan + '-' + props.styleOfPlan)
      ? benefits.push(
          <div
            key={index}
            className={cx(
              classes.FormBenefitsBoxItem,
              benefitsItem.selected ? classes.active : ''
            )}
            onClick={() =>
              props.benefitsItemHandler(props.category, benefitsItem.label)
            }
          >
            {benefitsItem.label}
          </div>
        )
      : ''
  );

  return (
    <div className={classes.FormBenefitsBoxItemWrapper}>
      {props.benefits === '-' ||
      typeof props.benefits === 'undefined' ||
      benefits.length <= 0 ? (
        <div className={classes.noBenefitItem}>No Benefits</div>
      ) : (
        benefits
      )}
    </div>
  );
};

export default FormBenefitsBoxItem;
