import React, { Component } from 'react';
import FormTitle from '../FormTitle/FormTitle';
import FormBenefitsBox from './FormBenefitsBox/FormBenefitsBox';
import classes from './FormBenefits.module.scss';
import FormController from '../../components/FormController/FormController';
import * as ROUTES from '../../constants/routes';

class FormBenefits extends Component {
  componentDidMount() {
    if (this.props.formStart === '') {
      this.props.history.push('/');
    }
    this.setState({ disabelSubmit: true });
    if (this.props.formStyle === 'ballpark') {
      this.props.updateSelectedChoices(
        ROUTES.PAGE_3_KEY,
        ROUTES.PAGE_3_CHOICES_9
      );
    }

    if (Object.keys(this.props[ROUTES.PAGE_4_KEY]).length > 0) {
      this.setState({
        [ROUTES.PAGE_4_BENEFIT_CAT_1]: this.props[ROUTES.PAGE_4_KEY][
          ROUTES.PAGE_4_BENEFIT_CAT_1
        ],
        [ROUTES.PAGE_4_BENEFIT_CAT_2]: this.props[ROUTES.PAGE_4_KEY][
          ROUTES.PAGE_4_BENEFIT_CAT_2
        ],
        [ROUTES.PAGE_4_BENEFIT_CAT_3]: this.props[ROUTES.PAGE_4_KEY][
          ROUTES.PAGE_4_BENEFIT_CAT_3
        ],
        [ROUTES.PAGE_4_BENEFIT_CAT_4]: this.props[ROUTES.PAGE_4_KEY][
          ROUTES.PAGE_4_BENEFIT_CAT_4
        ]
      });
    }
  }
  state = {
    /* Exclusivity */
    [ROUTES.PAGE_4_BENEFIT_CAT_1]: [
      {
        label: 'Postseason Guarantee',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_6 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_7 + '-' + ROUTES.PAGE_2_CHOICES_2
        ]
      },
      {
        label: 'Postseason pre-sale',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_5 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_7 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_8 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_9 + '-' + ROUTES.PAGE_2_CHOICES_3
        ]
      },
      {
        label: 'Opening Day Guarantee',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_7 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_9 + '-' + ROUTES.PAGE_2_CHOICES_3
        ]
      },
      {
        label: 'Opening Day Pre-Sale',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_7 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_8 + '-' + ROUTES.PAGE_2_CHOICES_2
        ]
      },
      {
        label: 'Opening Day Option',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_5 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_6 + '-' + ROUTES.PAGE_2_CHOICES_2
        ]
      },
      {
        label: 'Same Seat All Season',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_1
        ]
      },
      {
        label: 'Exclusive Events with Nationals Players',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_6 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_7 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_9 + '-' + ROUTES.PAGE_2_CHOICES_3
        ]
      },
      {
        label: 'Pre-sales for Postseason & Concerts',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_6 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_1
        ]
      },
      {
        label: 'Pre-sales for Big Games & Concerts',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_5 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_7 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_8 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_9 + '-' + ROUTES.PAGE_2_CHOICES_3
        ]
      },
      {
        label: 'Fan Rewards Points Program',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_5 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_8 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_9 + '-' + ROUTES.PAGE_2_CHOICES_3,
          ROUTES.PAGE_3_CHOICES_6 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_7 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_1
        ]
      }
    ],
    /* Savings */
    [ROUTES.PAGE_4_BENEFIT_CAT_2]: [
      {
        label: 'Eat Free 1 Designated Day of the Week',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_6 + '-' + ROUTES.PAGE_2_CHOICES_2
        ]
      },
      {
        label: 'Savings off single-game ticket price',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_5 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_6 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_7 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_8 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_9 + '-' + ROUTES.PAGE_2_CHOICES_3
        ]
      },
      {
        label: 'Concessions Savings',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_5 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_6 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_7 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_8 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_9 + '-' + ROUTES.PAGE_2_CHOICES_3
        ]
      },
      {
        label: 'Team Store Savings',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_5 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_6 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_7 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_8 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_9 + '-' + ROUTES.PAGE_2_CHOICES_3
        ]
      },
      {
        label: 'Discounts on additional ticket purchases',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_5 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_6 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_7 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_8 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_9 + '-' + ROUTES.PAGE_2_CHOICES_3
        ]
      }
    ],
    /* Convenience */
    [ROUTES.PAGE_4_BENEFIT_CAT_3]: [
      {
        label: 'Build my own schedule',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_5 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_6 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_7 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_8 + '-' + ROUTES.PAGE_2_CHOICES_2
        ]
      },
      {
        label: 'Dedicated Ballpark Entrance',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_6 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_7 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_8 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_9 + '-' + ROUTES.PAGE_2_CHOICES_3
        ]
      },
      {
        label: "Exchange tickets for games You can't make",
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_1
        ]
      },
      {
        label: "Unlimited exchanges for games You can't make",
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1
        ]
      },
      {
        label: 'Dedicated Nationals service representative',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_6 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_7 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_8 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_9 + '-' + ROUTES.PAGE_2_CHOICES_3,
          ROUTES.PAGE_3_CHOICES_5 + '-' + ROUTES.PAGE_2_CHOICES_1
        ]
      },
      {
        label: 'Dedicated Concessions Lines',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_6 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_7 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_8 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_9 + '-' + ROUTES.PAGE_2_CHOICES_3
        ]
      }
    ],
    /* No need for this version */
    [ROUTES.PAGE_4_BENEFIT_CAT_4]: [
      {
        label: 'Free Guaranteed Gate Giveaways',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1
        ]
      },
      {
        label: 'Fan Rewards Points Program',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_5 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_3
        ]
      },
      {
        label:
          'Ability to earn Nationals loyalty points and convert them into ballpark spending money',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_5 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_3
        ]
      },
      {
        label: 'Free subscription to MLB.tv Premium',
        selected: false,
        show: [
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_1,
          ROUTES.PAGE_3_CHOICES_2 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_3 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_4 + '-' + ROUTES.PAGE_2_CHOICES_2,
          ROUTES.PAGE_3_CHOICES_1 + '-' + ROUTES.PAGE_2_CHOICES_3
        ]
      }
    ]
  };

  benefitsItemHandler = (category, label) => {
    this.props.updateSelectedChoices('benefitSubmit', true);
    this.setState(
      prevState => ({
        [category]: prevState[category].map(obj =>
          obj.label === label
            ? Object.assign(obj, { selected: !obj.selected })
            : obj
        )
      }),
      () => {
        this.props.updateSelectedChoices(ROUTES.PAGE_4_KEY, this.state);
      }
    );

    this.setState({ disabelSubmit: false });
  };
  render() {
    return (
      <div>
        <div>
          <FormTitle title="Select the benefits that interest you most" />
          {this.props[ROUTES.PAGE_3_KEY] !== '' ? (
            <>
              <div className={classes.FormBenefitsBoxWrapper}>
                <FormBenefitsBox
                  title="Exclusivity"
                  benefits={this.state[ROUTES.PAGE_4_BENEFIT_CAT_1]}
                  sizeOfPlan={this.props[ROUTES.PAGE_3_KEY]}
                  styleOfPlan={this.props[ROUTES.PAGE_2_KEY]}
                  benefitsItemHandler={this.benefitsItemHandler}
                  category={ROUTES.PAGE_4_BENEFIT_CAT_1}
                />
                <FormBenefitsBox
                  title="Savings"
                  benefits={this.state[ROUTES.PAGE_4_BENEFIT_CAT_2]}
                  sizeOfPlan={this.props[ROUTES.PAGE_3_KEY]}
                  styleOfPlan={this.props[ROUTES.PAGE_2_KEY]}
                  benefitsItemHandler={this.benefitsItemHandler}
                  category={ROUTES.PAGE_4_BENEFIT_CAT_2}
                />
                <FormBenefitsBox
                  title="Convenience"
                  benefits={this.state[ROUTES.PAGE_4_BENEFIT_CAT_3]}
                  sizeOfPlan={this.props[ROUTES.PAGE_3_KEY]}
                  styleOfPlan={this.props[ROUTES.PAGE_2_KEY]}
                  benefitsItemHandler={this.benefitsItemHandler}
                  category={ROUTES.PAGE_4_BENEFIT_CAT_3}
                />
                {/* <FormBenefitsBox
                                    title="Rewards"
                                    benefits={this.state[ROUTES.PAGE_4_BENEFIT_CAT_4]}
                                    sizeOfPlan={this.props[ROUTES.PAGE_3_KEY]}
                                    styleOfPlan={this.props[ROUTES.PAGE_2_KEY]}
                                    benefitsItemHandler={this.benefitsItemHandler}
                                    category = {ROUTES.PAGE_4_BENEFIT_CAT_4}
                                /> */}
              </div>
              <FormController
                backTo={ROUTES.PAGE_3_LINK}
                nextTo={ROUTES.PAGE_5_LINK}
                startOver={ROUTES.PAGE_1_LINK}
                showBack={true}
                showStartOver={true}
                showSkip={true}
                showSubmit={true}
                disableSubmit={!this.props.benefitSubmit}
              />
            </>
          ) : (
            <div className={classes.errorMsg}>Please choose a package</div>
          )}
        </div>
      </div>
    );
  }
}

export default FormBenefits;
