import React from 'react';
import ticketFinderLogo from '../../assets/images/nats-ticketfinder-logo.png';
import classes from './Logo.module.scss';

const logo = props => (
  <div className={classes.Logo}>
    <img src={ticketFinderLogo} alt="TicketFinder Logo" />
  </div>
);

export default logo;
