import React from 'react';
import classes from './SeatingMap.module.scss';
import cx from 'classnames';
import SeatingMap from './SeatingMap';
import closeIcon from '../../assets/images/closeIcon.png';

const SeatMapModal = props => {
  return (
    <div
      className={cx(
        classes.modal,
        props.showMapModal === true ? classes.modalActive : ''
      )}
    >
      <div className={classes.modaBody}>
        <div className={classes.modaBody__map}>
          <SeatingMap
            seatsInfo={props.seatsInfo}
            seatInfoSelectedKey={props.seatInfoSelectedKey}
            hoveredItem={props.seatInfoHoveredKey}
          />
        </div>
        <img
          className={classes.closeIcon}
          src={closeIcon}
          alt="Close Icon"
          onClick={() => props.onClickCloseMapModal()}
        />
      </div>
    </div>
  );
};

export default SeatMapModal;
