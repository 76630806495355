import React from 'react';
import classes from './PlanUpsell.module.scss';

const planUpsell = props => (
  <div className={classes.PlanUpsell}>
    <div className={classes.title}>You may also consider:</div>
    <div className={classes.planTitle}>
      {props.planUpsellValuesSelected.plan}
    </div>
    <div className={classes.priceRange}>
      Starts At {props.planUpsellValuesSelected.priceRange}
    </div>
    <div className={classes.savings}>
      <strong>{props.planUpsellValuesSelected.avgSavings}</strong> Average
      Savings Off Single-Game Price
    </div>
    <div className={classes.benefit}>
      You will receive all the benefits included with the{' '}
      {props.planRecommendationSelected.plan} <strong>PLUS</strong>:
      <ul>
        {props.planUpsellValuesSelected.benefits.map((res, index) => (
          <li key={index}>{res}</li>
        ))}
      </ul>
    </div>
    <div
      className={classes.blueButton}
      onClick={() =>
        props.onClickChooseMyPlansToggle(props.planUpsellValuesSelected['slug'])
      }
    >
      Upgrade to <span>{props.planUpsellValuesSelected.plan}</span>
    </div>
    {/* <p className={classes.disclaimer}>
      Ticket brokers are not eligible for this offer.
    </p> */}
  </div>
);

export default planUpsell;
