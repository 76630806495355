import React from 'react';
import cx from 'classnames';
import classes from './TopNavigationItem.module.scss';
import { NavLink } from 'react-router-dom';

const topNavigationItem = props => {
  return (
    <>
      {props.active ? (
        <NavLink
          exact
          to={props.path}
          className={classes.TopNavigationItem}
          activeClassName={classes.active}
        >
          {props.navName}
        </NavLink>
      ) : (
        <div
          className={cx(
            classes.TopNavigationItem,
            classes.TopNavigationItemDisable
          )}
        >
          {props.navName}
        </div>
      )}
    </>
  );
};
export default topNavigationItem;
