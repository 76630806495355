import React from 'react';
import classes from './TicketLargeGroup.module.scss';
import * as ROUTES from '../../constants/routes';
import FormController from '../../components/FormController/FormController';

const TicketLargeGroup = props => {
  return (
    <>
      <div className={classes.TicketLargeGroup}>
        <div className={classes.FormTitle}>GROUP TICKETS & EXPERIENCES</div>
        <div className={classes.content}>
          <p>
            Nationals games are the perfect summer event for companies, schools,
            churches, associations, and youth sports leagues. Reserving a group
            is easy and a wide range of seating and pricing options are
            available. Plus, you will get tons of great benefits, including:
          </p>

          <div className={classes.content_col}>
            <div className={classes.content_col_items}>
              <div className={classes.content_col_item}>
                <img
                  src={require(`../../assets/images/icons/icons-2.png`)}
                  alt=""
                />
                <span>
                  Discounted tickets for groups of 13 or more - the larger the
                  group, the larger the savings!
                </span>
              </div>
              <div className={classes.content_col_item}>
                <img
                  src={require(`../../assets/images/icons/icons-3.png`)}
                  alt=""
                />
                <span>No per-ticket fees</span>
              </div>
              <div className={classes.content_col_item}>
                <img
                  src={require(`../../assets/images/icons/icons-1.png`)}
                  alt=""
                />
                <span>
                  Reserved seating, allowing your group to enjoy the game
                  together
                </span>
              </div>
              <div className={classes.content_col_item}>
                <img
                  src={require(`../../assets/images/icons/icons-7.png`)}
                  alt=""
                />
                <span>Scoreboard messages for groups of 50 or more</span>
              </div>
            </div>
            <div className={classes.content_col_items}>
              <div className={classes.content_col_item}>
                <img
                  src={require(`../../assets/images/icons/icons-5.png`)}
                  alt=""
                />
                <span>
                  Pregame hospitality areas and meeting space reservations
                  available
                </span>
              </div>
              <div className={classes.content_col_item}>
                <img
                  src={require(`../../assets/images/icons/icons-4.png`)}
                  alt=""
                />
                <span>
                  Unique on field experiences available, including pregame
                  performances, first pitch, play ball announcement, lineup card
                  delivery, Presidents Race finish line, and more!
                </span>
              </div>
              <div className={classes.content_col_item}>
                <img
                  src={require(`../../assets/images/icons/icons-6.png`)}
                  alt=""
                />
                <span>
                  Personalized service from your dedicated Account Executive
                  makes your experience easy from your initial order through
                  your event on game day
                </span>
              </div>
            </div>
          </div>
          <a
            href="https://purchase.tickets.com/buy/MLBEventInfo?agency=WSHN_GRP&pid=8727429&tfl=Washington_Nationals-tickets-Washington_Nationals:_Tickets:_Group_Tickets_presented_by_Homewood_Suites_by_Hilton_Washington_DC_Capitol_-_Navy_Yard-na-x0-Desktop-Landscape"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className={classes.greenButton}>Make Initial Payment</div>
          </a>
        </div>
      </div>
      <FormController
        backTo={ROUTES.PAGE_1_LINK}
        nextTo={ROUTES.PAGE_3_LINK}
        startOver={ROUTES.PAGE_1_LINK}
        showBack={true}
        showStartOver={false}
        showSkip={false}
        showSubmit={false}
      />
    </>
  );
};

export default TicketLargeGroup;
