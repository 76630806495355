import React from 'react';
import classes from './SelectNumOfSeatsModal.module.scss';
import SeatNumItem from './SeatNumItem/SeatNumItem';
import cx from 'classnames';

const selectNumOfSeats = props => {
  let seatsArr = [1, 2, 3, 4, 5, 6];

  return (
    <div
      className={cx(
        classes.modal,
        props.numOfSeatsModalConfirm === false ? classes.modalActive : ''
      )}
    >
      <div className={classes.modaBody}>
        <div className={classes.modalTitle}>
          How many {props.formStyle === 'classic' ? 'Seats ' : 'Plans '}
          would you like to purchase?
        </div>
        <div className={classes.seatNumWrapper}>
          {seatsArr.map((seatNum, index) => (
            <SeatNumItem
              key={index}
              seatNum={seatNum}
              onClickNumOfSeatItem={props.onClickNumOfSeatItem}
              numOfSeatsSelected={props.numOfSeatsSelected}
            />
          ))}
        </div>
        <div
          className={cx(
            classes.seatNumModalConfirm,
            props.numOfSeatsSelected !== '' ? classes.active : ''
          )}
          onClick={() => props.onClickNumOfSeatModalConfirm()}
        >
          Confirm
        </div>
      </div>
    </div>
  );
};

export default selectNumOfSeats;
