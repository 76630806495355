import React from 'react';
import classes from './FormControllerButton.module.scss';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';

const formControllerButton = props => {
  const { disableSubmit, controllerType } = props;

  // <div className={classes.FormControllerButton}>
  //     <div className={classes[props.controllerType]}>{props.children}</div>
  // </div>
  return (
    <>
      {!disableSubmit ? (
        <NavLink
          to={props.pathTo}
          className={cx(
            classes.FormControllerButton,
            classes[props.controllerType],
            controllerType === 'submit' ? classes.active_submit : ''
          )}
        >
          <div className={classes[props.controllerType]}>{props.children}</div>
        </NavLink>
      ) : (
        <div
          className={cx(
            classes.FormControllerButton,
            classes[props.controllerType],
            controllerType === 'submit' ? classes.disable_submit : ''
          )}
        >
          <div className={classes[props.controllerType]}>{props.children}</div>
        </div>
      )}
    </>
  );
};

export default formControllerButton;
