/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import cx from 'classnames';
import axios from 'axios';
import classes from './Checkout.module.scss';
import FormTitle from '../FormTitle/FormTitle';
import * as ROUTES from '../../constants/routes';
import FormController from '../../components/FormController/FormController';
import CheckoutForm from './CheckoutForm/CheckoutForm';
import PersonalForm from './PersonalForm/PersonalForm';

import checkIcon from '../../assets/images/check.png';
class Checkout extends Component {
  state = {
    loading: false,
    error: false,
    checkoutResult: '',
    cardInfoResult: '',
    personalInforResult: {},
    totalInfo: '',
    cartId: '',
    clientToken: '',
    validation: true,
    validationErrorMsg: '',
    checkoutSuccess: false,
    loaderShow: false,
    selectedPaymentPlan: {
      index: 0
    },
    numOfTickets: null
  };
  componentDidMount() {
    this.setState({ loaderShow: true });
    if (this.props.formStart === '') {
      this.props.history.push('/');
    }
    let ticketQuantity = null;

    switch (this.props.formSize) {
      case 'eightyFlexible':
        this.setState({ numOfTickets: this.props.numOfSeat * 80 });
        ticketQuantity = this.props.numOfSeat * 80;
        break;

      case 'fortyFlexible':
        this.setState({ numOfTickets: this.props.numOfSeat * 40 });
        ticketQuantity = this.props.numOfSeat * 40;

        break;

      case 'twentyFlexible':
        this.setState({ numOfTickets: this.props.numOfSeat * 20 });
        ticketQuantity = this.props.numOfSeat * 20;

        break;

      default:
        this.setState({ numOfTickets: this.props.numOfSeat });
        ticketQuantity = this.props.numOfSeat;

        break;
    }

    if (
      this.props['seatInfoSelected'] !== '' &&
      this.props['selectYourSeatsUuid'] !== ''
    ) {
      axios
        .post('https://nationals.vipfanportal.com/ticketfinder/createCart', {
          q: ticketQuantity,
          uuid: this.props['selectYourSeatsUuid'],
          auid: this.props['seatInfoSelected']['auid'],
          sid: this.props['seatInfoSelected']['section'], // section
          psid: this.props['seatInfoSelected']['ps-key'], // ps-key
          bid: this.props['seatInfoSelected']['buyers'][0] // buyers
        })
        .then(response => {
          console.log('res', response);

          this.setState({
            totalInfo: response.data.info.costs,
            seatLocation: response.data.info.location,
            paymentPlanOptions: response.data.info.costs.plans,
            selectedPaymentPlan: {
              ...this.state.selectedPaymentPlan,
              planPrice: response.data.info.costs.total,
              dueLater: response.data.info.costs.plans[0].dueLater,
              paymentType: 'payinfull'
            },
            clientToken:
              response.data.info['braintree-request-result']['braintreeToken'][
                'clientToken'
              ],
            cartId: response.data.info.cart,
            loaderShow: false
          });
          return response.data.info.location;
        })
        .catch(error => {
          this.setState({ error: true, loaderShow: false });
        });
    }
  }
  componentWillUnmount() {
    if (this.state.cartId) {
      axios
        .post('https://nationals.vipfanportal.com/ticketfinder/deleteCart', {
          cart: this.state['cartId']
        })
        .then(response => {})
        .catch(error => {
          this.setState({ error: true });
        });
    }
  }

  getState = zipcode => {
    //let thisst = '';
    let thisstate = '';
    // Ensure param is a string to prevent unpredictable parsing results
    if (typeof zipcode !== 'string') {
      console.log('Must pass the zipcode as a string.');
      return;
    }
    // Ensure you don't parse codes that start with 0 as octal values
    const thiszip = parseInt(zipcode, 10);
    // Code blocks alphabetized by state
    if (thiszip >= 35000 && thiszip <= 36999) {
      //thisst = 'AL';
      thisstate = 'Alabama';
    } else if (thiszip >= 99500 && thiszip <= 99999) {
      //thisst = 'AK';
      thisstate = 'Alaska';
    } else if (thiszip >= 85000 && thiszip <= 86999) {
      //thisst = 'AZ';
      thisstate = 'Arizona';
    } else if (thiszip >= 71600 && thiszip <= 72999) {
      //thisst = 'AR';
      thisstate = 'Arkansas';
    } else if (thiszip >= 90000 && thiszip <= 96699) {
      //thisst = 'CA';
      thisstate = 'California';
    } else if (thiszip >= 80000 && thiszip <= 81999) {
      //thisst = 'CO';
      thisstate = 'Colorado';
    } else if (thiszip >= 6000 && thiszip <= 6999) {
      //thisst = 'CT';
      thisstate = 'Connecticut';
    } else if (thiszip >= 19700 && thiszip <= 19999) {
      //thisst = 'DE';
      thisstate = 'Delaware';
    } else if (thiszip >= 32000 && thiszip <= 34999) {
      //thisst = 'FL';
      thisstate = 'Florida';
    } else if (thiszip >= 30000 && thiszip <= 31999) {
      //thisst = 'GA';
      thisstate = 'Georgia';
    } else if (thiszip >= 96700 && thiszip <= 96999) {
      //thisst = 'HI';
      thisstate = 'Hawaii';
    } else if (thiszip >= 83200 && thiszip <= 83999) {
      //thisst = 'ID';
      thisstate = 'Idaho';
    } else if (thiszip >= 60000 && thiszip <= 62999) {
      //thisst = 'IL';
      thisstate = 'Illinois';
    } else if (thiszip >= 46000 && thiszip <= 47999) {
      //thisst = 'IN';
      thisstate = 'Indiana';
    } else if (thiszip >= 50000 && thiszip <= 52999) {
      //thisst = 'IA';
      thisstate = 'Iowa';
    } else if (thiszip >= 66000 && thiszip <= 67999) {
      //thisst = 'KS';
      thisstate = 'Kansas';
    } else if (thiszip >= 40000 && thiszip <= 42999) {
      //thisst = 'KY';
      thisstate = 'Kentucky';
    } else if (thiszip >= 70000 && thiszip <= 71599) {
      //thisst = 'LA';
      thisstate = 'Louisiana';
    } else if (thiszip >= 3900 && thiszip <= 4999) {
      //thisst = 'ME';
      thisstate = 'Maine';
    } else if (thiszip >= 20600 && thiszip <= 21999) {
      //thisst = 'MD';
      thisstate = 'Maryland';
    } else if (thiszip >= 1000 && thiszip <= 2799) {
      //thisst = 'MA';
      thisstate = 'Massachusetts';
    } else if (thiszip >= 48000 && thiszip <= 49999) {
      //thisst = 'MI';
      thisstate = 'Michigan';
    } else if (thiszip >= 55000 && thiszip <= 56999) {
      //thisst = 'MN';
      thisstate = 'Minnesota';
    } else if (thiszip >= 38600 && thiszip <= 39999) {
      //thisst = 'MS';
      thisstate = 'Mississippi';
    } else if (thiszip >= 63000 && thiszip <= 65999) {
      //thisst = 'MO';
      thisstate = 'Missouri';
    } else if (thiszip >= 59000 && thiszip <= 59999) {
      //thisst = 'MT';
      thisstate = 'Montana';
    } else if (thiszip >= 27000 && thiszip <= 28999) {
      //thisst = 'NC';
      thisstate = 'North Carolina';
    } else if (thiszip >= 58000 && thiszip <= 58999) {
      //thisst = 'ND';
      thisstate = 'North Dakota';
    } else if (thiszip >= 68000 && thiszip <= 69999) {
      //thisst = 'NE';
      thisstate = 'Nebraska';
    } else if (thiszip >= 88900 && thiszip <= 89999) {
      //thisst = 'NV';
      thisstate = 'Nevada';
    } else if (thiszip >= 3000 && thiszip <= 3899) {
      //thisst = 'NH';
      thisstate = 'New Hampshire';
    } else if (thiszip >= 7000 && thiszip <= 8999) {
      //thisst = 'NJ';
      thisstate = 'New Jersey';
    } else if (thiszip >= 87000 && thiszip <= 88499) {
      //thisst = 'NM';
      thisstate = 'New Mexico';
    } else if (thiszip >= 10000 && thiszip <= 14999) {
      //thisst = 'NY';
      thisstate = 'New York';
    } else if (thiszip >= 43000 && thiszip <= 45999) {
      //thisst = 'OH';
      thisstate = 'Ohio';
    } else if (thiszip >= 73000 && thiszip <= 74999) {
      //thisst = 'OK';
      thisstate = 'Oklahoma';
    } else if (thiszip >= 97000 && thiszip <= 97999) {
      //thisst = 'OR';
      thisstate = 'Oregon';
    } else if (thiszip >= 15000 && thiszip <= 19699) {
      //thisst = 'PA';
      thisstate = 'Pennsylvania';
    } else if (thiszip >= 300 && thiszip <= 999) {
      //thisst = 'PR';
      thisstate = 'Puerto Rico';
    } else if (thiszip >= 2800 && thiszip <= 2999) {
      //thisst = 'RI';
      thisstate = 'Rhode Island';
    } else if (thiszip >= 29000 && thiszip <= 29999) {
      //thisst = 'SC';
      thisstate = 'South Carolina';
    } else if (thiszip >= 57000 && thiszip <= 57999) {
      //thisst = 'SD';
      thisstate = 'South Dakota';
    } else if (thiszip >= 37000 && thiszip <= 38599) {
      //thisst = 'TN';
      thisstate = 'Tennessee';
    } else if (
      (thiszip >= 75000 && thiszip <= 79999) ||
      (thiszip >= 88500 && thiszip <= 88599)
    ) {
      //thisst = 'TX';
      thisstate = 'Texas';
    } else if (thiszip >= 84000 && thiszip <= 84999) {
      //thisst = 'UT';
      thisstate = 'Utah';
    } else if (thiszip >= 5000 && thiszip <= 5999) {
      //thisst = 'VT';
      thisstate = 'Vermont';
    } else if (thiszip >= 22000 && thiszip <= 24699) {
      //thisst = 'VA';
      thisstate = 'Virgina';
    } else if (thiszip >= 20000 && thiszip <= 20599) {
      //thisst = 'DC';
      thisstate = 'Washington DC';
    } else if (thiszip >= 98000 && thiszip <= 99499) {
      //thisst = 'WA';
      thisstate = 'Washington';
    } else if (thiszip >= 24700 && thiszip <= 26999) {
      //thisst = 'WV';
      thisstate = 'West Virginia';
    } else if (thiszip >= 53000 && thiszip <= 54999) {
      //thisst = 'WI';
      thisstate = 'Wisconsin';
    } else if (thiszip >= 82000 && thiszip <= 83199) {
      //thisst = 'WY';
      thisstate = 'Wyoming';
    } else {
      //thisst = 'none';
      thisstate = 'none';
    }
    return thisstate;
  };
  onClickCheckoutSubmit = (title, checkoutInfo) => {
    if (this.isEmpty(this.state.personalInforResult.firstName)) {
      console.log('1');
      this.setState({
        validationErrorMsg: 'First Name is required',
        validation: false
      });
      return;
    }
    if (this.isEmpty(this.state.personalInforResult.lastName)) {
      console.log('2');
      this.setState({
        validationErrorMsg: 'Last Name is required',
        validation: false
      });
      return;
    }
    if (!this.isEmail(this.state.personalInforResult.email)) {
      console.log('3');
      this.setState({
        validationErrorMsg: 'Invalid Email',
        validation: false
      });
      return;
    }
    if (this.isEmpty(this.state.personalInforResult.address)) {
      console.log('4');
      this.setState({
        validationErrorMsg: 'Address is required',
        validation: false
      });
      return;
    }
    if (this.isEmpty(this.state.personalInforResult.zip)) {
      console.log('5');
      this.setState({
        validationErrorMsg: 'Zip Code is required',
        validation: false
      });
      return;
    }
    if (this.getState(this.state.personalInforResult.zip) === 'none') {
      console.log('6');
      this.setState({
        validationErrorMsg: 'Zip Code is not valid',
        validation: false
      });
      return;
    }

    if (
      checkoutInfo === null ||
      checkoutInfo === 'undefined' ||
      title === 'Error'
    ) {
      console.log('7');
      this.setState({
        validationErrorMsg: 'Invalid Card Information',
        validation: false
      });
      return;
    }

    this.setState({
      validationErrorMsg: '',
      validation: true,
      cardInfoResult: checkoutInfo,
      loaderShow: true
    });

    // console.log('checkout', {
    //   price: this.state.selectedPaymentPlan.planPrice,
    //   paymentType: this.state.selectedPaymentPlan.paymentType,
    //   dueLater: this.state.selectedPaymentPlan.dueLater,
    //   cart: this.state.cartId,
    //   'nonce-id': checkoutInfo.nonce,
    //   email: this.state.personalInforResult.email,
    //   last: this.state.personalInforResult.lastName,
    //   first: this.state.personalInforResult.firstName,
    //   address: this.state.personalInforResult.address,
    //   city: this.state.personalInforResult.city,
    //   state: this.getState(this.state.personalInforResult.zip),
    //   zip: this.state.personalInforResult.zip
    // });
    // setTimeout(() => {
    //   this.setState({
    //     checkoutSuccess: false,
    //     loaderShow: false
    //   });
    // }, 1000);

    axios
      .post('https://nationals.vipfanportal.com/ticketfinder/checkoutCart', {
        price: this.state.selectedPaymentPlan.planPrice,
        paymentType: this.state.selectedPaymentPlan.paymentType,
        dueLater: this.state.selectedPaymentPlan.dueLater,
        cart: this.state.cartId,
        'nonce-id': checkoutInfo.nonce,
        email: this.state.personalInforResult.email,
        last: this.state.personalInforResult.lastName,
        first: this.state.personalInforResult.firstName,
        address: this.state.personalInforResult.address,
        city: this.state.personalInforResult.city,
        state: this.getState(this.state.personalInforResult.zip),
        zip: this.state.personalInforResult.zip
      })
      .then(response => {
        setTimeout(() => {
          this.setState({
            checkoutSuccess: true,
            loaderShow: false
          });
        }, 2000);
      })
      .catch(error => {
        console.log('error', error);
        this.setState({
          error: true,
          loaderShow: false
        });
      });
  };

  onChangePersonalForm = result => {
    this.setState({
      personalInforResult: result
    });
  };

  onChangePaymentPlan = value => {
    this.setState({
      selectedPaymentPlan: {
        ...this.state.selectedPaymentPlan,
        planId: value.id,
        paymentType:
          this.state.totalInfo.total === value.due ? 'payinfull' : value.id,
        planPrice: value.due,
        dueLater: value.dueLater,
        index: value.index
      }
    });
  };

  // validations
  isEmail = email => {
    // eslint-disable-next-line no-useless-escape
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  };
  isEmpty = value => {
    return typeof value === 'undefined' || value === '';
  };
  render() {
    let planTitle = '';
    let planSubTitle = '';
    let parkingLink = '';
    let rows = [];
    let section = '';
    let flexMultiply = 1;

    switch (this.props['selectYourSeatsUuid']) {
      case 'xa79PAlovb':
        parkingLink =
          'https://purchase.tickets.com/buy/MLBEventInfo?agency=MLBSS&pid=8751096';
        planTitle = '82-Game Classic Plan';
        break;
      case 'zN6VE3n0B5':
        parkingLink =
          'https://purchase.tickets.com/buy/MLBEventInfo?agency=MLBSS&pid=8751099';
        planTitle = '42-Game Classic Plan';
        planSubTitle = '(Friday/Sunday)';
        break;
      case 'CAFbHgRVNg':
        parkingLink =
          'https://purchase.tickets.com/buy/MLBEventInfo?agency=MLBSS&pid=8751102';
        planTitle = '42-Game Classic Plan';
        planSubTitle = '(Saturday/Midweek)';
        break;
      case 'buTMiiX4Er':
        parkingLink =
          'https://purchase.tickets.com/buy/MLBEventInfo?agency=MLBSS&pid=8751105';
        planTitle = '22-Game Classic Plan';
        planSubTitle = "(Abe's Plan)";
        break;
      case 'lRcyJMLnKk':
        parkingLink =
          'https://purchase.tickets.com/buy/MLBEventInfo?agency=MLBSS&pid=8751108';
        planTitle = '22-Game Classic Plan';
        planSubTitle = "(George's Plan)";
        break;
      case 'TLpAWWaMkk':
        parkingLink =
          'https://purchase.tickets.com/buy/MLBEventInfo?agency=MLBSS&pid=8751111';
        planTitle = '22-Game Classic Plan';
        planSubTitle = "(Tom's Plan)";
        break;
      case '9XZEeo7qMz':
        parkingLink =
          'https://purchase.tickets.com/buy/MLBEventInfo?agency=MLBSS&pid=8751114';
        planTitle = '22-Game Classic Plan';
        planSubTitle = "(Teddy's Plan)";
        break;
      case 'beInIw45nx':
        parkingLink = '';
        planTitle = '10-Game Classic Plan';
        planSubTitle = '(Big Game Plan)';
        break;
      case 'EJ41wkGOLk':
        parkingLink = '';
        planTitle = '10-Game Classic Plan';
        planSubTitle = "(Teddy's Plan)";
        break;
      case 'uy55P4lmFk':
        parkingLink = '';
        planTitle = '10-Game Classic Plan';
        planSubTitle = '(Sunday Plan)';
        break;
      case '54C8ZCSb4s':
        parkingLink = '';
        planTitle = '5-Game Classic Plan';
        planSubTitle = '(Bobblehead Plan)';
        break;
      case 'v0C11qgztN':
        parkingLink = '';
        planTitle = '5-Game Classic Plan';
        planSubTitle = '(Opening Day Plan)';
        break;
      case 'baxawZnlK1':
        parkingLink = '';
        planTitle = '5-Game Classic Plan';
        planSubTitle = '(Saturday Plan)';
        break;
      case '6WPuBrtT8C':
        planTitle = '80-Ticket Flexible Plan';
        flexMultiply = 80;
        break;
      case 'yHsZtmvU3N':
        planTitle = '40-Ticket Flexible Plan';
        flexMultiply = 40;
        break;
      case 'LHsIqghb0k':
        planTitle = '20-Ticket Flexible Plan';
        flexMultiply = 20;
        break;
      default:
        break;
    }

    if (this.state.seatLocation) {
      rows.push(this.state.seatLocation.rows);
      section = this.state.seatLocation.section;
    }

    // try {
    //   Object.keys(this.state.seatLocation).forEach(key => {
    //     if (this.state.seatLocation.hasOwnProperty(key)) {
    //       // rows.push({ [key]: this.state.seatLocation[key] });
    //     }
    //   });
    // } catch (error) {
    //   // console.log('error', error);
    // }
    // rows.push({ H: [21, 23] }, { Y: [25, 26, 65, 78, 2] });
    console.log('state', this.state.selectedPaymentPlan);
    return (
      <>
        <FormTitle title="Checkout" />
        {this.state.checkoutSuccess ? (
          <div className={classes.Checkout}>
            <div className={classes.Checkout__success}>
              <img src={checkIcon} alt="" />
              <h3>Success!</h3>
              <h4>THANK YOU FOR YOUR PURCHASE</h4>
              {(this.props.formSize === 'eightyClassic' ||
                this.props.formSize === 'fortyClassic' ||
                this.props.formSize === 'twentyClassic') && (
                <>
                  <p>
                    Check out the link below to purchase a Parking package
                    corresponding to your schedule of games
                  </p>
                  <a
                    href={parkingLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Purchase Parking
                  </a>
                </>
              )}
            </div>
          </div>
        ) : (
          <div>
            {this.state.totalInfo !== '' ? (
              <>
                <div className={classes.Checkout}>
                  <div className={classes.orderSummary}>
                    <div className={classes.orderSummaryTitle}>Your Order</div>
                    <div className={classes.orderSummaryPlan}>
                      <span className={classes.labelName}>
                        {planTitle}
                        <br />
                        {planSubTitle}
                      </span>
                      <span>
                        $
                        {this.props.formStyle === 'classic'
                          ? this.props.seatInfoSelected.price.toLocaleString(
                              'en-US'
                            )
                          : (
                              this.props.seatInfoSelected.price * flexMultiply
                            ).toLocaleString('en-US')}
                        <br />
                        per{' '}
                        {this.props.formStyle === 'classic' ? 'seat' : 'plan'}
                      </span>
                    </div>
                    <div className={classes.orderSummaryPlan}>
                      {this.props.seatInfoSelected && this.state.seatLocation && (
                        <>
                          <span className={classes.labelName}>
                            Seating Location
                          </span>
                          <span>
                            {this.props.seatInfoSelected.name}
                            <br />
                            {section}
                            <br />
                            {this.props.formStyle === 'classic' && (
                              <div className={classes.seatingInfo}>
                                {rows &&
                                  rows.map((item, i) => {
                                    return Object.keys(item).map(key => {
                                      if (Array.isArray(item[key])) {
                                        return (
                                          <div key={(key + i).toString()}>
                                            <span>
                                              <span
                                                className={
                                                  classes.seatingInfo_left_text
                                                }
                                              >
                                                Row:{' '}
                                              </span>
                                              <span>{key}</span>
                                              <br />
                                              <span>
                                                <span
                                                  className={
                                                    classes.seatingInfo_left_text
                                                  }
                                                >
                                                  Seat(s):{' '}
                                                </span>
                                                {item[key].map((seat, j) => (
                                                  <span
                                                    key={(key + j).toString()}
                                                  >
                                                    {' '}
                                                    {seat}
                                                    {j + 1 < item[key].length
                                                      ? ', '
                                                      : ''}
                                                  </span>
                                                ))}
                                              </span>
                                            </span>
                                            <br />
                                            <br />
                                          </div>
                                        );
                                      }
                                      return false;
                                    });
                                  })}
                              </div>
                            )}{' '}
                          </span>
                        </>
                      )}
                    </div>

                    <div className={classes.orderSummaryPlan}>
                      {this.props.numOfSeat && (
                        <>
                          <span className={classes.labelName}>Quantity</span>
                          <span>{this.props.numOfSeat}</span>
                        </>
                      )}
                    </div>
                    <div className={classes.orderSummaryCost}>
                      <span className={classes.labelName}>Subtotal</span>
                      <span>
                        ${this.state.totalInfo.subTotal.toLocaleString('en-US')}
                      </span>
                    </div>
                    <div className={classes.orderSummaryCost}>
                      <span className={classes.labelName}>Handling Charge</span>
                      <span>
                        $
                        {this.state.totalInfo.serviceCharges.toLocaleString(
                          'en-US'
                        )}
                      </span>
                    </div>
                    <div className={classes.orderSummaryCost}>
                      <span className={classes.labelName}>Total Due Now</span>
                      <span>
                        $
                        {this.state.selectedPaymentPlan.planPrice.toLocaleString(
                          'en-US'
                        )}
                      </span>
                    </div>
                  </div>
                  <div className={classes.formInput}>
                    <div className={classes.formTitle}>Personal Info</div>
                    <PersonalForm
                      onChangePersonalForm={this.onChangePersonalForm}
                    />
                    <div className={classes.formTitle}>Payment Info</div>
                    <CheckoutForm
                      authorization={this.state.clientToken}
                      cartId={this.state.cartId}
                      onClickCheckoutSubmit={this.onClickCheckoutSubmit}
                      validationErrorMsg={this.state.validationErrorMsg}
                      validation={this.state.validation}
                    />
                    {/* {this.state.selectedPaymentPlan.index !== 0 && ( */}
                    <>
                      {console.log('aa', this.state.selectedPaymentPlan.index)}
                      <div className={classes.formTitle}>Payment Plans</div>
                      <div className={classes.formPaymentPlan}>
                        {this.state.paymentPlanOptions.map((option, i) => {
                          return (
                            <label
                              key={option.id}
                              className={classes.formPaymentPlan__input}
                            >
                              <input
                                type="radio"
                                className={this.props.className}
                                name="paymentPlan"
                                checked={
                                  this.state.selectedPaymentPlan.index === i
                                    ? true
                                    : false
                                }
                                key={option.id}
                                onChange={this.onChangePaymentPlan.bind(this, {
                                  ...option,
                                  index: i
                                })}
                                value={option.id}
                              />
                              {option.description}
                            </label>
                          );
                        })}
                      </div>
                    </>
                    {/* )} */}
                  </div>
                </div>
              </>
            ) : (
              <div className={classes.errorMsg}>
                {this.state.error && (
                  <p> Sorry! there is an error, please try again later.</p>
                )}
              </div>
            )}
          </div>
        )}
        <FormController
          backTo={'select-your-seats'}
          nextTo=""
          startOver={ROUTES.PAGE_1_LINK}
          showBack={true}
          showStartOver={true}
          showSkip={false}
          showSubmit={false}
        />

        {this.state.loaderShow && (
          <div className={cx(classes.Checkout, classes.Checkout__loader)}>
            <div
              className={cx(
                classes.loader__wrapper,
                this.state.loaderShow ? classes.loader__wrapper__active : ''
              )}
            >
              <div className={classes.loader}>
                <div className={classes['lds-ring']}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default Checkout;
