import React from 'react';
import classes from './FormBenefitsBox.module.scss';
import FormBenefitsBoxItem from './FormBenefitsBoxItem/FormBenefitsBoxItem'
const FormBenefitsBox = (props) => {
    return(
        <div className={classes.FormBenefitsBox}>
            <div className={classes._FormBenefitsBox}>
                <div className={classes.title}>{props.title}</div>
                <FormBenefitsBoxItem 
                    benefits={props.benefits} 
                    sizeOfPlan={props.sizeOfPlan} 
                    styleOfPlan={props.styleOfPlan} 
                    category={props.category} 
                    benefitsItemHandler={props.benefitsItemHandler}
                />
            </div>
        </div>
    );
}

export default FormBenefitsBox;